import React, { useContext,  useEffect,  useReducer, useState } from "react";
import {Collapse} from 'react-bootstrap';
import { Link } from "react-router-dom";
import {MenuList} from './Menu';
import { ThemeContext } from "../../../context/ThemeContext";
import {useScrollPosition} from "@n8tb1t/use-scroll-position";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import * as endpoints from '../../../Constants/Endpoints'
import { useDispatch } from 'react-redux';
import { navtoggle } from "../../../store/actions/AuthActions";

const reducer = (previousState, updatedState) => ({
  ...previousState,
  ...updatedState,
});

const initialState = {
  active : "",
  activeSubmenu : "",
}

const SideBar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    iconHover,
    sidebarposition,
    headerposition,
    sidebarLayout,
    ChangeIconSidebar,
  } = useContext(ThemeContext);
  
  const [state, setState] = useReducer(reducer, initialState);
  const handleMenuActive = status => {
    setState({active : status});			
    if(state.active === status){				
      setState({active : ""});
    }
  }
  const handleSubmenuActive = (status) => {		
    setState({activeSubmenu : status})
    if(state.activeSubmenu === status){
      setState({activeSubmenu : ""})			
    }    
  }

  const menuClick = async (route) => {
      var url = '/'+route
      navigate(url)
      
    try {
      const res = await axios.get(process.env.REACT_APP_API_SERVICE_BASE_URL + endpoints.Authenticated, {
        withCredentials: true
      })
      if (res.status === 200){
          navigate(url)
          
      }
  }
  catch(e) {
      navigate(0)
  }
  dispatch(navtoggle("NAVCLOSE"));
  }
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true)
	useScrollPosition(
		({ prevPos, currPos }) => {
		  const isShow = currPos.y > prevPos.y
		  if (isShow !== hideOnScroll) setHideOnScroll(isShow)
		},
		[hideOnScroll]
	)

  // ForAction Menu
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1]; 

  useEffect(() => {
    MenuList.forEach((data) => {
      data.content?.forEach((item) => {        
        if(path === item.to){         
          setState({active : data.title})          
        }
        item.content?.forEach(ele => {
          if(path === ele.to){
            setState({activeSubmenu : item.title, active : data.title})
          }
        })
      })
  })
  },[path]);
    return (
      <div
        onMouseEnter={()=>ChangeIconSidebar(true)}
        onMouseLeave={()=>ChangeIconSidebar(false)}
        className={`deznav ${iconHover} ${
          sidebarposition.value === "fixed" &&
          sidebarLayout.value === "horizontal" &&
          headerposition.value === "static"
            ? hideOnScroll > 120
              ? "fixed"
              : ""
            : ""
        }`}
    >
      
          <div className="deznav-scroll">
            <ul className="metismenu" id="menu">
              {MenuList.map((data, index)=>{
                  let menuClass = data.classsChange;
                    if(menuClass === "menu-title"){
                      return(
                        <li className={menuClass}  key={index} >{data.title}</li>
                      )
                    }else{
                      return(
                        
                        <li className={`has-menu ${ state.active === data.title ? 'mm-active' : ''}${data.to === path ? 'mm-active' : ''} `}
                          key={index}
                        >
                          {data.content && data.content.length > 0 ?
                            <>
                              <Link to={"#"} 
                                className="has-arrow ai-icon"
                                onClick={() => {handleMenuActive(data.title)}}
                              >
                                  {data.iconStyle}{" "}
                                  <span className="nav-text">{data.title}
                                    <span className="badge badge-xs style-1 badge-danger ms-2">{data.update}</span>
                                  </span>
                              </Link>
                          
                              <Collapse in={state.active === data.title ? true :false}>
                                <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                  {data.content && data.content.map((data,index) => {									
                                    return(	
                                        <li key={index}
                                          className={`${ state.activeSubmenu === data.title ? "mm-active" : ""}${data.to === path ? 'mm-active' : ''}`}                                    
                                        >
                                          {data.content && data.content.length > 0 ?
                                              <>
                                                <Link className={data.hasMenu ? 'has-arrow' : ''}
                                                  onClick={() => { handleSubmenuActive(data.title)}}
                                                >
                                                  {data.title}
                                                </Link>
                                                <Collapse in={state.activeSubmenu === data.title ? true :false}>
                                                    <ul className={`${menuClass === "mm-collapse" ? "mm-show" : ""}`}>
                                                      {data.content && data.content.map((data,index) => {
                                                        return(	                                                    
                                                          <li key={index}>
                                                            <Link className={`${path === data.to ? "mm-active" : ""}`} to={data.to}>{data.title}</Link>
                                                          </li>
                                                        )
                                                      })}
                                                    </ul>
                                                </Collapse>
                                              </>
                                            :
                                            <Link
                                              className={`${data.to === path ? 'mm-active' : ''}`}
                                              onClick={() => {menuClick(data.to);}}
                                            >
                                              {data.title}
                                            </Link>
                                          }
                                          
                                        </li>
                                      
                                    )
                                  })}
                                </ul>
                              </Collapse>
                            </>
                            :
                            <Link 
                            className={`${data.to === path ? 'mm-active' : ''}`}
                            onClick={() => {setState({active : ""}); menuClick(data.to);}}
                            >
                              {data.iconStyle}{" "}
                              <span className="nav-text">{data.title}
                                <span className="badge badge-xs style-1 badge-danger ms-2">{data.update}</span>
                              </span>
                            </Link>
                          }
                        </li>	
                      )
                  }
              })}  
            </ul>           
          </div>
      </div>
    );
  
}

export default SideBar;
