import axios from 'axios';
import * as endPoints from '../Constants/Endpoints'
// import swal from "sweetalert";
import Swal from "sweetalert2";
import {
    loginConfirmedAction,
    Logout,
} from '../store/actions/AuthActions';

export function signUp(email, password, name) {
    //axios call
    const postData = {
        name,
        email,
        password,
    };
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Register,
        postData,
    );
}

export function login(email, password) {
    const postData = {
        email,
        password,
    };
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.ValidateLogin, postData,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function logout() {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Logout,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function formatError(errorResponse) {
    switch (errorResponse) {
        case 'A user with this email already exists':
            //return 'Email already exists';
            // swal("Oops", "Email already exists", "error");
              Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Email already exists',                        
              })
            break;
        case 'Email not found':
             Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Email not found',                        
              })
            //return 'Email not found';
                //swal("Oops", "Email not found", "error",{ button: "Try Again!",});
           break;
        case 'Password incorrect':
            //return 'Invalid Password';
            // swal("Oops", "Invalid Password", "error",{ button: "Try Again!",});
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Incorrect Password',                        
            })
            break;
        case 'USER_DISABLED':
            return 'User Disabled';

        default:
            Swal.fire({
                icon: 'error',
                title: 'Oops',
                text: 'Something went wrong, please try again',                        
            })
    }
}

export function saveBarberShopNameInLocalStorage(name) {
    localStorage.setItem('name', JSON.stringify(name));
}

export function runLogoutTimer(dispatch, timer, navigate) {
    setTimeout(() => {
        //dispatch(Logout(history));
        dispatch(Logout(navigate));
    }, timer);
}

export function checkAutoLogin(dispatch, navigate) {
    const tokenDetailsString = localStorage.getItem('userDetails');

    let tokenDetails = '';
    if (!tokenDetailsString) {
        dispatch(Logout(navigate));
		return;
    }

    tokenDetails = JSON.parse(tokenDetailsString);
    let expireDate = new Date(tokenDetails.expireDate);
    let todaysDate = new Date();

    if (todaysDate > expireDate) {
        dispatch(Logout(navigate));
        return;
    }
		
    dispatch(loginConfirmedAction(tokenDetails));
	
    const timer = expireDate.getTime() - todaysDate.getTime();
    runLogoutTimer(dispatch, timer, navigate);
}
