import React from "react";
import Calendar from 'react-calendar';
import { useState } from "react";

const StepThree = () => {
   const [selectedDate, setSelectedDate] = useState("")

   return (
      <section>
                 <div className="h-80">
                    <Calendar
                    // minDate={Date.UTC}
                    disablePast
                    onChange={e => {setSelectedDate(e.toDateString())}}
                    />
                    <p>{selectedDate}</p>
        </div>


      </section>
   );
};

export default StepThree;
