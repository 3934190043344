import { Navigate, useLocation } from "react-router-dom"
import axios from "axios"
import { useState, useEffect } from "react"
import * as endPoints from '../Constants/Endpoints'

export const useAuth = () => {
    const [isAuth, setIsAuth] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Authenticated, {
                    withCredentials: true
                })
                setIsAuth(false);
                if (res.status === 200){
                    setIsAuth(true);
                }
            }
            catch(e) {
                setIsAuth(false);
            }
        };
        fetchData();
    }, []);
return isAuth;
}

const ProtectedRoute = ({ children }) => {
    const isAuth = useAuth();
    let location = useLocation();
    if (isAuth === null)
    return null;
    return isAuth? children : location.pathname === '/page-register' ? <Navigate to="/page-register" /> : <Navigate to="/login" />;
};

export default ProtectedRoute;