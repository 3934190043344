import React from "react";
import avatar1 from '../../../../images/avatar/3.png'

const StepOne = () => {
   return (
      <section>
      <div className='row'>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      <div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6'>
            <div className='card'>
               <div className='card-body text-center profile-bx' style={{borderRight: 'none'}}>
               <div className='profile-image mb-4'>
                  <img src={avatar1} className='rounded-circle' alt="pro" />
                  </div>
                  <h4 className='fs-22 text-black mb-1'>Oda Dink</h4>
               </div>
         </div>
      </div>
      </div>


         {/* <div className="row">
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">First Name <span className="required">*</span></label>
                  <input
                     type="text"
                     name="firstName"
                     className="form-control"
                     placeholder="Parsley"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Last Name*</label>
                  <input
                     type="text"
                     name="lastName"
                     className="form-control"
                     placeholder="Montana"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Email Address <span className="required">*</span></label>
                  <input
                     type="email"
                     className="form-control"
                     id="inputGroupPrepend2"
                     aria-describedby="inputGroupPrepend2"
                     placeholder="example@example.com.com"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-6 mb-2">
               <div className="form-group mb-3">
                  <label className="text-label">Phone Number <span className="required">*</span></label>
                  <input
                     type="number"
                     name="phoneNumber"
                     className="form-control"
                     placeholder="(+1)408-657-9007"
                     required
                  />
               </div>
            </div>
            <div className="col-lg-12 mb-3">
               <div className="form-group mb-3">
                  <label className="text-label">Where are you from*</label>
                  <input
                     type="text"
                     name="place"
                     className="form-control"
                     required
                  />
               </div>
            </div>
         </div> */}
      </section>
   );
};

export default StepOne;
