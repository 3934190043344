import { Suspense, useEffect, } from 'react';

/// Components
import Index from "./jsx";
import { connect } from 'react-redux';
import {  useLocation , useNavigate , useParams } from 'react-router-dom';
// action
// import { isLogin } from './services/AuthService';
import { isAuthenticated } from './store/selectors/AuthSelectors';
/// Style

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import 'react-calendar/dist/Calendar.css';
// import Error404 from './jsx/pages/Error404';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();
	
    return (
      <Component
        {...props}
        router={{ location, navigate, params }}
      />
    );
  }
  return ComponentWithRouterProp;
}

function App (props) {  
      useEffect(() => {
      }, []);
		  return (
			  <>
            <Suspense fallback={              
                <div id="preloader">                
                    <div className="sk-three-bounce">
                        <div className="sk-child sk-bounce1"></div>
                        <div className="sk-child sk-bounce2"></div>
                        <div className="sk-child sk-bounce3"></div>
                    </div>
                </div>  
              }
            >
                <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"></meta>
              <Index />
              
            </Suspense>
        </>
      );    
};


const mapStateToProps = (state) => {
    return {
        isAuthenticated: isAuthenticated(state),
    };
};

//export default connect((mapStateToProps)(App)); 
export default withRouter(connect(mapStateToProps)(App)); 

