import './BarberShop.css';
import React, { useReducer, useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { ToastContainer} from "react-toastify";
import { ColorPicker } from 'primereact/colorpicker';
import Cropper from 'react-easy-crop'
import {
    GetServices,
    AddBarber,
    GetBarbers,
    GetBarberWorkingTimes,
    EditBarber,
    DeleteBarber,
    ActivateBarber,
    DisableBarber
} from './Requests';
import {
    notifySuccess,
    notifyError
} from '../Response/ResposeHandler'

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CropIcon from '@mui/icons-material/Crop';

const ManageBarbers = () => {
    const [times, setTimes] = useState()
    const [sundayCheck, setSundayCheck] = useState(false)
    const [mondayCheck, setMondayCheck] = useState(false)
    const [tuesdayCheck, setTuesdayCheck] = useState(false)
    const [wednesdayCheck, setWednesdayCheck] = useState(false)
    const [thursdayCheck, setThursdayCheck] = useState(false)
    const [fridayCheck, setFridayCheck] = useState(false)
    const [saturdayCheck, setSaturdayCheck] = useState(false)
    const [sundayStartHours, setSundayStartHours] = useState()
    const [sundayEndHours, setSundayEndHours] = useState()
    const [mondayStartHours, setMondayStartHours] = useState()
    const [mondayEndHours, setMondayEndHours] = useState()
    const [tuesdayStartHours, setTuesdayStartHours] = useState()
    const [tuesdayEndHours, setTuesdayEndHours] = useState()
    const [wednesdayStartHours, setWednesdayStartHours] = useState()
    const [wednesdayEndHours, setWednesdayEndHours] = useState()
    const [thursdayStartHours, setThursdayStartHours] = useState()
    const [thursdayEndHours, setThursdayEndHours] = useState()
    const [fridayStartHours, setFridayStartHours] = useState()
    const [fridayEndHours, setFridayEndHours] = useState()
    const [saturdayStartHours, setSaturdayStartHours] = useState()
    const [saturdayEndHours, setSaturdayEndHours] = useState()
    const [services, setServices] = useState([])
    const [barberName, setBarberName] = useState('')
    const [barberServices, setBarberServices] = useState([])
    const [barberRawData, setBarberRawData] = useState(null);
    const [barberData, setBarberData] = useState(null);
    const [file, setFile] = useState();
    const [cropImageActive, setCropImageActive] = useState(false)
    const [onEditPreviewImage, setOnEditPreviewImage] = useState(null)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const rotation = 0;
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [isCropButtonLoading, setIsCropButtonLoading] = useState(false)
    const [isAddBarberButtonLoading, setIsAddBarberButtonLoading] = useState(false)
    const [isActivateBarberButtonLoading , setIsActivateBarberButtonLoading] = useState(false)
    const [isDisableBarberButtonLoading , setIsDisableBarberButtonLoading] = useState(false)
    const [isDeleteBarberButtonLoading , setIsDeleteBarberButtonLoading] = useState(false)
    const [barberColour, setBarberColour] = useState("#ffbaba")
    const [isEdit, setIsEdit] = useState(false)
    const [barberId, setBarberId] = useState(null)
    let errorsObj = {
        barberName: '',
        barberServices: '',
        BarberImage: '',
        BarberHours: '',
        sundayErrors: '',
        mondayErrors: '',
        tuesdayErrors: '',
        wednesdayErrors: '',
        thursdayErrors: '',
        fridayErrors: '',
        saturdayErrors: ''
    };
    const [errors, setErrors] = useState(errorsObj);
    const allowedFileTypes = ['image/png', 'image/jpeg'];

const initialState = false;
const reducer = (state, action) =>{
    switch (action.type){
        case 'addBarberOpen':
            return { ...state, addBarber: true }
        case 'addBarberClose':			
            return { ...state, addBarber: false }
        case 'addSetHoursOpen':
            return { ...state, setBarberHours: true }
        case 'addSetHoursClose':
            return { ...state, setBarberHours: false }
        case 'cropImageOpen':
            return { ...state, cropImage: true }
        case 'cropImageClose':
            return { ...state, cropImage: false }
        default:
        return state	
    }	
}

useEffect(() => {
    GetBarberWorkingTimesWrapper()
    GetBarbersWrapper()
    GetServicesWrapper()
    // eslint-disable-next-line
}, []);

const ClearIndicatorStyles = (base, state) => ({
    ...base,
    cursor: "pointer",
    color: state.isFocused ? "#525252" : "#7e7e7e",
});

const ClearIndicator = (props) => {
    const {
    getStyles,
    innerProps: { ref, ...restInnerProps },
    } = props;
    return (
        <div
            {...restInnerProps}
            ref={ref}
            style={getStyles("clearIndicator", props)}
        >
            <div style={{ padding: "0px 5px" }}>Clear</div>
        </div>
    );
};

const resetFields = () => {
    setOnEditPreviewImage()
    setBarberColour("ffbaba")
    setSundayCheck(false);
    setMondayCheck(false);
    setTuesdayCheck(false);
    setWednesdayCheck(false);
    setThursdayCheck(false);
    setFridayCheck(false);
    setSaturdayCheck(false);
    setBarberName('');
    setBarberServices('');
    setFile();
    setCroppedImage(null);
    setSundayStartHours();
    setSundayEndHours();
    setMondayStartHours();
    setMondayEndHours();
    setTuesdayStartHours();
    setTuesdayEndHours();
    setWednesdayStartHours();
    setWednesdayEndHours();
    setThursdayStartHours();
    setThursdayEndHours();
    setFridayStartHours();
    setFridayEndHours();
    setSaturdayStartHours();
    setSaturdayEndHours();
    setErrors(errorsObj);
    setCropImageActive(false);
    setIsCropButtonLoading(false);
    setIsAddBarberButtonLoading(false);
}

function onSetWorkingHoursClick(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (barberName === '') {
        errorObj.barberName = 'Name is required';
        error = true;
    }
    if (barberServices.length === 0 || barberServices === null || barberServices === undefined) {
        errorObj.barberServices = 'At least one service is required';
        error = true;
    }
    if (!isEdit) {
        console.log(file)
        if (file === null || file === undefined) {
            errorObj.barberImage = 'Please upload an image';
            error = true;
        } else {
            if (!allowedFileTypes.includes(file.type)) {
                errorObj.barberImage = 'Only PNG or JPG files allowed';
                error = true;
            }
            if (croppedImage === null || croppedImage === undefined) {
                errorObj.barberImage = 'Crop you image';
                error = true;
            }
        }
    } else {
        if (file !== undefined) {
            if (!allowedFileTypes.includes(file.type)) {
                errorObj.barberImage = 'Only PNG or JPG files allowed';
                error = true;
            }
        }
    }
    setErrors(errorObj);
    if (error) {
return;
}
    dispatch({type:'addBarberClose'});
    dispatch({type:'addSetHoursOpen'});
}

function GetServicesWrapper() {
GetServices().then((res) => {
    var tempVar = []
    // eslint-disable-next-line
    res.data.map((item, index) => {
        var obj = { value: item.serviceId, label: item.name, isDisabled: item.status === 1 ? false : true }
        tempVar.push(obj)
    })
    setServices(tempVar)
}).catch((error) => {
    notifyError("Unable to retrieve services, please try again")
})
}

function GetBarbersWrapper() {
    setBarberData(null)
    GetBarbers().then((res) => {
        var data = []
        res.data.forEach(element => {
            var returnData = createData(element.barberId, element.name, element.imageFile, element.serviceNames, element.status, element.sunday, element.monday, element.tuesday, element.wednesday, element.thursday, element.friday, element.saturday, element.barberColour)
            data.push(returnData)
        });
        setBarberData(data)
        setBarberRawData(res.data);
        console.log(res.data)
    }).catch((error) => {
        notifyError("Unable to retrieve barbers, please try again")
    })
}

function GetBarberWorkingTimesWrapper() {
    GetBarberWorkingTimes().then((res) => {
        setTimes(res.data)
    }).catch((error) => {
        notifyError("Unable to retrieve working times, please try again")
    })
}

function SaveFile(e) {
    setFile(e.target.files[0])
    setCropImageActive(true)
    dispatch({type:'addBarberClose'})
    dispatch({type:'cropImageOpen'})
}

function OnDeleteService(barberId) {
    setIsDeleteBarberButtonLoading(true)
    DeleteBarber(barberId).then((res) => {
        notifySuccess("Barber removed")
        GetBarbersWrapper()
      }).catch((error) => {
        notifyError("Unable to remove barber, please try again")
      }).finally((f) => {
        setIsDeleteBarberButtonLoading(false)
      })
}
function OnEditBarber(barberId) {
    resetFields()
    setIsEdit(true)
    setBarberId(barberId)
    setErrors(errorsObj);
    setFile()
    setCroppedImage(null)
    const barberToEdit = barberRawData.filter(item => item.barberId === barberId)[0];

    if(barberToEdit.serviceNames.length === 0) {
        setBarberServices('');
    } else {
        var serv = [];
        barberToEdit.serviceNames.forEach(s => { serv.push(s.key)})
        var servicesToSelect = services.filter(item => serv.includes(item.label))

        setBarberServices(servicesToSelect)
    }
    setBarberColour(barberToEdit.barberColour)
    setBarberName(barberToEdit.name)
    setOnEditPreviewImage('data:image/jpeg;base64,' + barberToEdit.imageFile)

    if (barberToEdit.sunday.start !== null) {
        setSundayCheck(true)
        var sundayStartTimeToSelect = times.filter(item => item.label === barberToEdit.sunday.start)[0]
        var sundayEndTimeToSelect = times.filter(item => item.label === barberToEdit.sunday.end)[0]
        setSundayStartHours(sundayStartTimeToSelect);
        setSundayEndHours(sundayEndTimeToSelect);
    }

    if (barberToEdit.monday.start !== null) {
        setMondayCheck(true)
        var mondayStartTimeToSelect = times.filter(item => item.label === barberToEdit.monday.start)[0]
        var mondayEndTimeToSelect = times.filter(item => item.label === barberToEdit.monday.end)[0]
        setMondayStartHours(mondayStartTimeToSelect);
        setMondayEndHours(mondayEndTimeToSelect);
    }

    if (barberToEdit.tuesday.start !== null) {
        setTuesdayCheck(true)
        var tuesdayStartTimeToSelect = times.filter(item => item.label === barberToEdit.tuesday.start)[0]
        var tuesdayEndTimeToSelect = times.filter(item => item.label === barberToEdit.tuesday.end)[0]
        setTuesdayStartHours(tuesdayStartTimeToSelect);
        setTuesdayEndHours(tuesdayEndTimeToSelect);
    }

    if (barberToEdit.wednesday.start !== null) {
        setWednesdayCheck(true)
        var wednesdayStartTimeToSelect = times.filter(item => item.label === barberToEdit.wednesday.start)[0]
        var wednesdayEndTimeToSelect = times.filter(item => item.label === barberToEdit.wednesday.end)[0]
        setWednesdayStartHours(wednesdayStartTimeToSelect);
        setWednesdayEndHours(wednesdayEndTimeToSelect);
    }
    
    if (barberToEdit.thursday.start !== null) {
        setThursdayCheck(true)
        var thursdayStartTimeToSelect = times.filter(item => item.label === barberToEdit.thursday.start)[0]
        var thursdayEndTimeToSelect = times.filter(item => item.label === barberToEdit.thursday.end)[0]
        setThursdayStartHours(thursdayStartTimeToSelect);
        setThursdayEndHours(thursdayEndTimeToSelect);
    }

    if (barberToEdit.friday.start !== null) {
        setFridayCheck(true)
        var fridayStartTimeToSelect = times.filter(item => item.label === barberToEdit.friday.start)[0]
        var fridayEndTimeToSelect = times.filter(item => item.label === barberToEdit.friday.end)[0]
        setFridayStartHours(fridayStartTimeToSelect);
        setFridayEndHours(fridayEndTimeToSelect);
    }
    
    if (barberToEdit.saturday.start !== null) {
        setSaturdayCheck(true)
        var saturdayStartTimeToSelect = times.filter(item => item.label === barberToEdit.saturday.start)[0]
        var saturdayEndTimeToSelect = times.filter(item => item.label === barberToEdit.saturday.end)[0]
        setSaturdayStartHours(saturdayStartTimeToSelect);
        setSaturdayEndHours(saturdayEndTimeToSelect);
    }
    dispatch({type:'addBarberOpen'});
  }

function OnSubmitClick(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (sundayCheck) {
        if (sundayStartHours === null || sundayStartHours === undefined || sundayEndHours === null || sundayEndHours === undefined)
        {
        errorObj.sundayErrors = 'Required';
        error = true;
        } else {
            if (sundayStartHours.value >= sundayEndHours.value) {
                errorObj.sundayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (mondayCheck) {
        if (mondayStartHours === null || mondayStartHours === undefined || mondayEndHours === null || mondayEndHours === undefined)
        {
        errorObj.mondayErrors = 'Required';
        error = true;
        } else {
            if (mondayStartHours.value >= mondayEndHours.value) {
                errorObj.mondayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (tuesdayCheck) {
        if (tuesdayStartHours === null || tuesdayStartHours === undefined || tuesdayEndHours === null || tuesdayEndHours === undefined)
        {
        errorObj.tuesdayErrors = 'Required';
        error = true;
        } else {
            if (tuesdayStartHours.value >= tuesdayEndHours.value) {
                errorObj.tuesdayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (wednesdayCheck) {
        if (wednesdayStartHours === null || wednesdayStartHours === undefined || wednesdayEndHours === null || wednesdayEndHours === undefined)
        {
        errorObj.wednesdayErrors = 'Required';
        error = true;
        } else {
            if (wednesdayStartHours.value >= wednesdayEndHours.value) {
                errorObj.wednesdayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (thursdayCheck) {
        if (thursdayStartHours === null || thursdayStartHours === undefined || thursdayEndHours === null || thursdayEndHours === undefined)
        {
        errorObj.thursdayErrors = 'Required';
        error = true;
        } else {
            if (thursdayStartHours.value >= thursdayEndHours.value) {
                errorObj.thursdayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (fridayCheck) {
        if (fridayStartHours === null || fridayStartHours === undefined || fridayEndHours === null || fridayEndHours === undefined)
        {
        errorObj.fridayErrors = 'Required';
        error = true;
        } else {
            if (fridayStartHours.value >= fridayEndHours.value) {
                errorObj.fridayErrors = 'Invalid start time';
                error = true;
            }
        }
    }

    if (saturdayCheck) {
        if (saturdayStartHours === null || saturdayStartHours === undefined || saturdayEndHours === null || saturdayEndHours === undefined)
        {
        errorObj.saturdayErrors = 'Required';
        error = true;
        } else {
            if (saturdayStartHours.value >= saturdayEndHours.value) {
                errorObj.saturdayErrors = 'Invalid start time';
                error = true;
            }
        }
    }
    setErrors(errorObj);
    if (error) {
  return;
} else {
    setIsAddBarberButtonLoading(true)
}
    var serviceIds =[]
    barberServices.forEach((item) => {
        serviceIds.push(item.value)
    })

    var sundayStartHoursVar = sundayCheck ? sundayStartHours.value : -1
    var sundayEndHoursVar = sundayCheck ? sundayEndHours.value : 0
    var mondayStartHoursVar = mondayCheck ? mondayStartHours.value : -1
    var mondayEndHoursVar = mondayCheck ? mondayEndHours.value : 0
    var tuesdayStartHoursVar = tuesdayCheck ? tuesdayStartHours.value : -1
    var tuesdayEndHoursVar = tuesdayCheck ? tuesdayEndHours.value : 0
    var wednesdayStartHoursVar = wednesdayCheck ? wednesdayStartHours.value : -1
    var wednesdayEndHoursVar = wednesdayCheck ? wednesdayEndHours.value : 0
    var thursdayStartHoursVar = thursdayCheck ? thursdayStartHours.value : -1
    var thursdayEndHoursVar = thursdayCheck ? thursdayEndHours.value : 0
    var fridayStartHoursVar = fridayCheck ? fridayStartHours.value : -1
    var fridayEndHoursVar = fridayCheck ? fridayEndHours.value : 0
    var saturdayStartHoursVar = saturdayCheck ? saturdayStartHours.value : -1
    var saturdayEndHoursVar = saturdayCheck ? saturdayEndHours.value : 0

    if (!isEdit) {
    AddBarber(barberName,
        serviceIds,
        croppedImage,
        sundayStartHoursVar,
        sundayEndHoursVar,
        mondayStartHoursVar, 
        mondayEndHoursVar,
        tuesdayStartHoursVar,
        tuesdayEndHoursVar,
        wednesdayStartHoursVar,
        wednesdayEndHoursVar,
        thursdayStartHoursVar,
        thursdayEndHoursVar,
        fridayStartHoursVar,
        fridayEndHoursVar,
        saturdayStartHoursVar,
        saturdayEndHoursVar,
        barberColour
        ).then((res) => {
            notifySuccess("Barber added")
            dispatch({type:'addSetHoursClose'})
            GetBarbersWrapper()
        }).catch((e) => {
            notifyError("Unable to add barber, please try again")
        }).finally((t) => {
            setIsAddBarberButtonLoading(false)
        })
    } else {
        EditBarber(barberId,
            barberName,
            serviceIds,
            croppedImage,
            sundayStartHoursVar,
            sundayEndHoursVar,
            mondayStartHoursVar, 
            mondayEndHoursVar,
            tuesdayStartHoursVar,
            tuesdayEndHoursVar,
            wednesdayStartHoursVar,
            wednesdayEndHoursVar,
            thursdayStartHoursVar,
            thursdayEndHoursVar,
            fridayStartHoursVar,
            fridayEndHoursVar,
            saturdayStartHoursVar,
            saturdayEndHoursVar,
            barberColour).then((res) => {
                notifySuccess("Barber edited")
                dispatch({type:'addSetHoursClose'})
                GetBarbersWrapper()
            }).catch((error) => {
                notifyError("Unable to edit barber, please try again")
            }).finally((f) => {
                setIsAddBarberButtonLoading(false)
            })
    }
}

function OnDisableBarber(barberId) {
    setIsDisableBarberButtonLoading(true)
    DisableBarber(barberId).then((res)=> {
        notifySuccess("Barber disabled")
        GetBarbersWrapper()
    }).catch((error) => {
        notifyError("Unable to disable barber, please try again")
    }).finally((f) => {
        setIsDisableBarberButtonLoading(false)
    })
}

function OnActivateBarber(barberId) {
    setIsActivateBarberButtonLoading(true)
    ActivateBarber(barberId).then((res)=> {
        notifySuccess("Barber activated")
        GetBarbersWrapper()
    }).catch((error) => {
        notifyError("Unable to activate barber, please try again")
    }).finally((f) => {
        setIsActivateBarberButtonLoading(false)
    })
}
    const [state, dispatch] = useReducer(reducer, initialState);
    function Row(props) {
        const { row } = props;
        const [open, setOpen] = React.useState(false);     
        return (
        <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <td>
            <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
                >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
            </td>
            <td className="py-2">
                        <div className="media d-flex align-items-center">
                        <div className="avatar avatar-xl me-2">
                            {/* <img
                                loading='lazy'
                                className="rounded-circle img-fluid"
                                src={'data:image/jpeg;base64,' + row.image}
                                width="40"
                                alt=""
                            /> */}
                        </div>
                        </div>
                    </td>
            <td>
            <span
                 component="th" style={{backgroundColor: "#" + row.barberColour}} className={`badge badge-md`} >{row.name}
                 </span>
                 </td>
            <td>
                <span className={`badge badge-sm light ${ row.status === 1 ? 'badge-success' : 'badge-danger'} `}>
                    {row.status === 1 ? 'Active' : 'Disabled'}
                </span>
                </td>
            <td className="py-2 text-end">
            <Dropdown className="dropdown text-sans-serif">
                <Dropdown.Toggle
                    variant=""
                    className="btn btn-primary tp-btn-light sharp i-false"
                    type="button"
                    id="order-dropdown-0"
                    data-toggle="dropdown"
                    data-boundary="viewport"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <span>
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="18px"
                            height="18px"
                            viewBox="0 0 24 24"
                            version="1.1"
                        >
                        <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                            <rect y={0} width={24} height={24}/>
                            <circle fill="#000000" cx={5} cy={12} r={2}/>
                            <circle fill="#000000" cx={12} cy={12} r={2}/>
                            <circle fill="#000000" cx={19} cy={12} r={2}/>
                        </g>
                        </svg>
                </span>
                </Dropdown.Toggle>
                <Dropdown.Menu
                    className="dropdown-menu dropdown-menu-right border py-0"
                    aria-labelledby="order-dropdown-0"
                >
                    <div className="py-2">
                        {row.status === 1 ?
                        isDisableBarberButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                        <Link to={''} onClick={() => {OnDisableBarber(row.barberId)}} className="dropdown-item">Disable</Link>
                        :
                        isActivateBarberButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                        <Link onClick={() => {OnActivateBarber(row.barberId)}} className="dropdown-item">Active</Link>}
                        <Link to={''} className="dropdown-item" onClick={() => {OnEditBarber(row.barberId);}} >Edit</Link>
                        <div className="dropdown-divider" />
                        { isDeleteBarberButtonLoading ?
                        <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        :
                        <Link to={''} onClick={() => {OnDeleteService(row.barberId);}} className="dropdown-item text-danger">Delete</Link>}
                    </div>
                </Dropdown.Menu>
                </Dropdown>
                </td>
            </TableRow>
            <tr className="btn-reveal-trigger">
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" className="mb-3 mt-2" gutterBottom component="div">
                    <img
                                loading='lazy'
                                className="me-4 ms-1 mb-2 mt-2 rounded-circle img-fluid"
                                src={'data:image/jpeg;base64,' + row.image}
                                width="60"
                                alt=""
                            />
                    </Typography>
                    <Table size="small" aria-label="purchases">
                    <thead>
                    <tr className="btn-reveal-trigger">
                        {/* <th></th> */}
                        <th>Services</th>
                        <th>Working Hours</th>
                        </tr>
                    </thead>
                    <tbody>

                        {row.history.map((historyRow, index) => (
                        <tr key={index} className="btn-reveal-trigger">
                            <td style={{padding:'6px 0px'}}>
                                <span className={` ${ historyRow.serviceNames.status === 0 ? 'badge-danger badge badge light badge-sm' : ''} `}>{historyRow.serviceNames.serviceName}</span>                           
                            </td>
                            <td style={{padding:'6px 0px'}}>
                                <span>{index === 0 ? "Sunday:" : index === 1 ? "Monday:" : index === 2 ? "Tuesday:" : index === 3 ? "Wednesday:" : index === 4 ? "Thursday:" : index === 5 ? "Friday:" : index === 6 ? "Saturday:" : ""} </span>
                                <span>{historyRow.day.start === null ? "Not working" : <b> {historyRow.day.start + " - " + historyRow.day.end} </b>}</span>
                            </td>
                        </tr>
                        ))}
                    </tbody>
                </Table>
                </Box>
            </Collapse>
        </TableCell>
    </tr>
</React.Fragment>
    );
}
        function createData(barberId, name, image, serviceNames, status, sunday, monday, tuesday, wednesday, thursday, friday, saturday, barberColour) {
            var daysArray = [sunday, monday, tuesday, wednesday, thursday, friday, saturday]
            const buildArray = [];
            for (var i=0; i < daysArray.length; i++) {
                var obj = {day: daysArray[i], serviceNames: { serviceName :serviceNames[i] === undefined ? "" : serviceNames[i].key, status: serviceNames[i] === undefined ? "" : serviceNames[i].value}}
                buildArray.push(obj)
            }
            return {
            barberId,
            name,
            image,
            status,
            barberColour,
            history: buildArray,
            };
        }

        const createImage = async (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    // image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation)
  
    return {
      width:
        Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  }

  function readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.addEventListener('load', () => resolve(reader.result), false)
      reader.readAsDataURL(file)
    })
  }

        async function getCroppedImg(
            imageSrc,
            pixelCrop,
            rotation = 0,
            flip = { horizontal: false, vertical: false }
          ) {

 
            const image = await createImage(imageSrc)
            const canvas = document.createElement('canvas')
            const ctx = canvas.getContext('2d')
          
            if (!ctx) {
              return null
            }
          
            const rotRad = getRadianAngle(rotation)
          
            // calculate bounding box of the rotated image
            const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
              image.width,
              image.height,
              rotation
            )
          
            // set canvas size to match the bounding box
            canvas.width = bBoxWidth
            canvas.height = bBoxHeight
          
            // translate canvas context to a central location to allow rotating and flipping around the center
            ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
            ctx.rotate(rotRad)
            ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
            ctx.translate(-image.width / 2, -image.height / 2)
          
            // draw rotated image
            ctx.drawImage(image, 0, 0)
          
            const croppedCanvas = document.createElement('canvas')
          
            const croppedCtx = croppedCanvas.getContext('2d')
          
            if (!croppedCtx) {
              return null
            }
          
            // Set the size of the cropped canvas
            croppedCanvas.width = pixelCrop.width
            croppedCanvas.height = pixelCrop.height
          
            // Draw the cropped image onto the new canvas
            croppedCtx.drawImage(
              canvas,
              pixelCrop.x,
              pixelCrop.y,
              pixelCrop.width,
              pixelCrop.height,
              0,
              0,
              pixelCrop.width,
              pixelCrop.height
            )
          
            // As Base64 string
            // return croppedCanvas.toDataURL('image/jpeg');
          
            // As a blob
            return new Promise((resolve, reject) => {
              croppedCanvas.toBlob((file) => {
                resolve(file)
              }, 'image/jpeg')
            })
          }

        const onCropComplete = (croppedArea, croppedAreaPixels) => {
            setCroppedAreaPixels(croppedAreaPixels)
          }
          
          const showCroppedImage = async () => {
            setIsCropButtonLoading(true);
            try {
                let imageDataUrl = await readFile(file)

              const croppedImageResult = await getCroppedImg(
                imageDataUrl,
                croppedAreaPixels,
                rotation
              )
              setCroppedImage(croppedImageResult)
              setIsCropButtonLoading(false)
            dispatch({type:'addBarberOpen'})
            dispatch({type:'cropImageClose'})
            } catch (e) {
              console.error(e)
            }
          }
    
    return (
    <div className="h-80">
        <PageTitle activeMenu="Mange Barbers" motherMenu="Barber Shop" />
        <div className="mb-2" style={{display: 'grid', justifyContent: 'end'}}>
            <Link to={"#"} className="btn-custom btn-primary mb-1 ms-1" onClick={() => {dispatch({type:'addBarberOpen'}); resetFields(); setIsEdit(false)}}>Add Barber</Link>
        </div>
        <ToastContainer />
        <div className="row">
            <div className="col-lg-12">
            <div className="card">
                <div className="card-body ">
                    <div className="profile-statistics">
                    <Modal className="modal fade" show={state.addBarber} centered>
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">{isEdit ? 'Edit Barber' : 'Add Barber'}</h5>
                            <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'addBarberClose'})}>
                            </Button>
                        </div>
                        <div className="modal-body">
                            <form className="comment-form" onSubmit={onSetWorkingHoursClick}>
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="form-group mb-3">
                                    <label htmlFor="author" className="text-black font-w600">  Name <span className="required">*</span> </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        placeholder="name"
                                        value={barberName}
                                        onChange={(e) => setBarberName(e.target.value)} />
                                        {errors.barberName && <div className="mt-2 text-danger fs-12">{errors.barberName}</div>}
                                </div>
                                <div className="mb-3">
                                <label htmlFor="author" className="text-black font-w600">  Select services <span className="required">*</span> </label>
                                <Select
                                    closeMenuOnSelect={false}
                                    components={{ ClearIndicator }}
                                    styles={{ clearIndicator: ClearIndicatorStyles }}
                                    isMulti
                                    style={{height:'none'}}
                                    defaultValue={barberServices}
                                    options={services}
                                    onChange={(e) => setBarberServices(e)}
                                />
                                {errors.barberServices && <div className="mt-2 text-danger fs-12">{errors.barberServices}</div>}
                                </div>
                                <div>
                                <label htmlFor="author" className="text-black font-w600">  Upload photo <span className="required">*</span> </label>
                                <div className="input-group custom_file_input mb-3">
                                    <div className="form-file">
                                        <input type="file" accept="image/png, image/jpeg" onChange={(SaveFile)} className="form-file-input form-control" />
                                    {<img className="mt-2 mb-0 rounded-circle img-fluid" src={croppedImage !== null && file !== undefined ? URL.createObjectURL(croppedImage) : onEditPreviewImage} width="60" alt=""/>}
                                        {errors.barberImage && <div className="text-danger fs-12">{errors.barberImage}</div>}
                                    </div>
                                </div>
                                <ColorPicker value={"#" + barberColour} onChange={(e) => setBarberColour(e.value)} />
                                </div>
                                </div>
                                <div className="col-lg-12">
                                <div className="form-group mt-3 mb-2">
                                    <Link to={"#"} className="btn-custom btn-primary mb-1 ms-1" onClick={onSetWorkingHoursClick}>Set working hours</Link>
                                </div>
                                </div>
                            </div>
                            </form>
                        </div>
                        </div>
                    </Modal>
                    <Modal className="modal fade" show={state.cropImage} centered>
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Crop Image</h5>
                            <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'cropImageClose'})}>
                            </Button>
                        </div>
                        <div className="modal-body">
                        {cropImageActive && file ? (
                            <React.Fragment>
                                {isCropButtonLoading ? <span style={{display:'block', marginLeft: 'auto', marginRight:'0'}} className="mb-3 spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                <IconButton
                                    size="small"
                                    onClick={showCroppedImage}
                                    onTouchStart={showCroppedImage}
                                    className="mb-2"
                                    style={{display:'block', marginLeft: 'auto', marginRight:'0'}}
                                    >
                                    <CropIcon />
                                </IconButton>
                                }
                                <div style ={{position:'relative', width:'100%', height:200}}>
                                    <Cropper
                                        image={file !== undefined ? URL.createObjectURL(file):''}
                                        crop={crop}
                                        zoom={zoom}
                                        onCropChange={setCrop}
                                        onCropComplete={onCropComplete}
                                        onZoomChange={setZoom}
                                        cropShape="round"
                                        cropSize={{width:190, height:190}}
                                    />
                                </div>
                            </React.Fragment>
                        ):<div></div>}
                        </div>
                    </div>
                    </Modal>
                    <Modal className="modal fade" show={state.setBarberHours} centered>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Set working hours</h5>
                                <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'addSetHoursClose'})}></Button>
                </div>
                <div className="modal-body">
                    <form className="comment-form" onSubmit={OnSubmitClick}>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='sundayCheck'
                                    defaultChecked={sundayCheck}
                                    onChange={(e) => { setSundayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='sundayCheck'>
                                    Sunday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!sundayCheck}
                                    defaultValue={sundayStartHours}
                                    onChange={(e) => {setSundayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.sundayErrors && <div className="mt-2 text-danger fs-12">{errors.sundayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!sundayCheck}
                                    defaultValue={sundayEndHours}
                                    onChange={(e) => {setSundayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='mondayCheck'
                                    defaultChecked={mondayCheck}
                                    onChange={(e) => { setMondayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='mondayCheck'>
                                    Monday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!mondayCheck}
                                    defaultValue={mondayStartHours}
                                    onChange={(e) => {setMondayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.mondayErrors && <div className="mt-2 text-danger fs-12">{errors.mondayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!mondayCheck}
                                    defaultValue={mondayEndHours}
                                    onChange={(e) => {setMondayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='tuesdayCheck'
                                    defaultChecked={tuesdayCheck}
                                    onChange={(e) => { setTuesdayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='tuesdayCheck'>
                                    Tuesday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!tuesdayCheck}
                                    defaultValue={tuesdayStartHours}
                                    onChange={(e) => {setTuesdayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.tuesdayErrors && <div className="mt-2 text-danger fs-12">{errors.tuesdayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!tuesdayCheck}
                                    defaultValue={tuesdayEndHours}
                                    onChange={(e) => {setTuesdayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='wednesdayCheck'
                                    defaultChecked={wednesdayCheck}
                                    onChange={(e) => { setWednesdayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='wednesdayCheck'>
                                    Wednesday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!wednesdayCheck}
                                    defaultValue={wednesdayStartHours}
                                    onChange={(e) => {setWednesdayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.wednesdayErrors && <div className="mt-2 text-danger fs-12">{errors.wednesdayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!wednesdayCheck}
                                    defaultValue={wednesdayEndHours}
                                    onChange={(e) => {setWednesdayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='thursdayCheck'
                                    defaultChecked={thursdayCheck}
                                    onChange={(e) => { setThursdayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='thursdayCheck'>
                                    Thursday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!thursdayCheck}
                                    defaultValue={thursdayStartHours}
                                    onChange={(e) => {setThursdayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.thursdayErrors && <div className="mt-2 text-danger fs-12">{errors.thursdayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!thursdayCheck}
                                    defaultValue={thursdayEndHours}
                                    onChange={(e) => {setThursdayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='fridayCheck'
                                    defaultChecked={fridayCheck}
                                    onChange={(e) => { setFridayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='fridayCheck'>
                                    Friday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!fridayCheck}
                                    defaultValue={fridayStartHours}
                                    onChange={(e) => {setFridayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.fridayErrors && <div className="mt-2 text-danger fs-12">{errors.fridayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!fridayCheck}
                                    defaultValue={fridayEndHours}
                                    onChange={(e) => {setFridayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                                <input
                                    type='checkbox'
                                    className='form-check-input'
                                    id='saturdayCheck'
                                    defaultChecked={saturdayCheck}
                                    onChange={(e) => { setSaturdayCheck(e.target.checked);}}
                                />
                                <label className='form-check-label' htmlFor='saturdayCheck'>
                                    Saturday
                                </label>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6  col-6">
                                <Select
                                    placeholder="Start"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!saturdayCheck}
                                    defaultValue={saturdayStartHours}
                                    onChange={(e) => {setSaturdayStartHours({value:e.value ,label: e.label})}}
                                />
                                {errors.saturdayErrors && <div className="mt-2 text-danger fs-12">{errors.saturdayErrors}</div>}
                            </div>
                            <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                                <Select
                                    placeholder="End"
                                    closeMenuOnSelect={true}
                                    options={times}
                                    isDisabled={!saturdayCheck}
                                    defaultValue={saturdayEndHours}
                                    onChange={(e) => {setSaturdayEndHours({value:e.value ,label: e.label})}}
                                />
                            </div>
                        </div>
                        <div className="col-lg-12 mb-5">
                    <div className="form-group mb-3" style={{display:'block'}}>
                        {!isAddBarberButtonLoading ? <Link style={{position:'absolute'}} to={"#"} className="mt-2 btn-custom btn-primary submit" onClick={() => {dispatch({type:'addBarberOpen'}); dispatch({type:'addSetHoursClose'});}}>Back</Link> : null}
                        {isAddBarberButtonLoading ?  <span style={{}} className="mt-4 spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                        <Link to={"#"} style={{position:'absolute', right:'0', marginRight:'2rem'}} className="mt-2 btn-custom btn-primary" onClick={OnSubmitClick}>{isEdit ? 'Edit barber' : 'Add barber'}</Link>
                        }
                    </div>
                    </div>
                    </form>
                </div>
            </div>
        </Modal>
    </div>
        {barberData == null ? <div className='barber-loader'></div>: barberData.length === 0 ? <div>No barbers</div> :
        <div className="table-responsive-sm">
        <table className="table table-sm mb-0 text-black">
        <thead>
            <tr className="btn-reveal-trigger">
            <th></th>
            <th className="ps-2"></th>
            <th className="align-middle ps-2">Name</th>
            <th className="align-middle ps-2">Status</th>
            <th className="no-sort text-end">Action</th>
        </tr>
            </thead>
        <tbody>
            {barberData == null ? <div>loading</div>:
            barberData.map((row, index) => (
                <Row key={index} row={row} index={index} />
                ))}
            </tbody>
            </table>
            </div>} 
                </div>
            </div>
            </div>
        </div>
    </div>
    );};

export default ManageBarbers;