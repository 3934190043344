import PageTitle from "../../../layouts/PageTitle";


const Bookings = () => {
    return (
        <div className="h-80">
        <PageTitle activeMenu="Bookings" motherMenu="" />
        </div>
    );
}

export default Bookings;