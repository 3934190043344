import React, {useState} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import logo from '../../images/logo-full.png'

const LockScreen = () => {
  const nav = useNavigate();
  const submitHandler = (e) => {
    e.preventDefault()
    nav('/dashboard')
  }

  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className='authincation'>
      <div className='container'>
        <div className='row justify-content-center h-100 align-items-center'>
          <div className='col-md-6'>
            <div className='authincation-content'>
              <div className='row no-gutters'>
                <div className='col-xl-12'>
                  <div className='auth-form'>
                    <div className='text-center mb-3'>
                      <Link to='/'>
                        <img src={logo} alt="" />
                      </Link>
                    </div>
                    <h4 className='text-center mb-4 text-white'>
                      Account Locked
                    </h4>
                    <form action='' onSubmit={(e) => submitHandler(e)}>
                      <div className='form-group position-relative'>
                        <label className='mb-1 text-white'><strong>Password</strong></label>
                        <input 
                          type={`${showPassword ? "text" : "password"}`}
                          className='form-control' defaultValue='Password' 
                          
                        />
                          <span className="show-pass eye"
                             onClick={() => setShowPassword(!showPassword)}
                          >
                            
                            {showPassword === false ? (<i className="fa fa-eye-slash" />) : (<i className="fa fa-eye" />)}
                          </span>
                      </div>
                      <div className='text-center'>
                        <input
                          type='submit'
                          value='Unlock'
                          className='btn bg-white text-primary btn-block'
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LockScreen
