import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { jwtDecode } from "jwt-decode";
import Cookies from 'js-cookie';
import * as ClaimTypes from '../../../Constants/ClaimTypes'
import { Link } from "react-router-dom";
import { navtoggle } from "../../../store/actions/AuthActions";
import { formatError, logout } from "../../../services/AuthService";

/// Scroll
// import PerfectScrollbar from "react-perfect-scrollbar";

/// Image
import profile from "../../../images/avatar/4.png";
import { Dropdown } from "react-bootstrap";
import { ThemeContext } from "../../../context/ThemeContext";
export const LOGIN_FAILED_ACTION = '[login action] failed login';

const Header = ({ onNote, toggle, onProfile, onNotification, onClick }) => {
  let touchstartX = null;
  let touchstartY = null;
  let touchendX = null;
  let touchendY = null;
  let navigate = useNavigate();
  let dispatch = useDispatch();
  var path = window.location.pathname.split("/");
  var name = path[path.length - 1].split("-");
  var filterName = name.length >= 4 ? name.filter((n, i) => i > 0) : name;
  var finalName = filterName.includes("app")
    ? filterName.filter((f) => f !== "app")
    : filterName.includes("ui")
    ? filterName.filter((f) => f !== "ui")
    : filterName.includes("uc")
    ? filterName.filter((f) => f !== "uc")
    : filterName.includes("basic")
    ? filterName.filter((f) => f !== "basic")
    : filterName.includes("jquery")
    ? filterName.filter((f) => f !== "jquery")
    : filterName.includes("table")
    ? filterName.filter((f) => f !== "table")
    : filterName.includes("page")
    ? filterName.filter((f) => f !== "page")
    : filterName.includes("email")
    ? filterName.filter((f) => f !== "email")
    : filterName.includes("ecom")
    ? filterName.filter((f) => f !== "ecom")
    : filterName.includes("chart")
    ? filterName.filter((f) => f !== "chart")
    : filterName.includes("editor")
    ? filterName.filter((f) => f !== "editor")
    : filterName;
    const {background, changeBackground} = useContext(ThemeContext);
    const [barberName, setBarberName] = useState(null)
    const [isLogoutButtonLoading, setIslogoutButtonLoading] = useState(false);

    useEffect(() => {
      // const accessToken = Cookies.get('Access-Token')
      // const decode = jwtDecode(accessToken)
      // const name = decode[ClaimTypes.name]
      setBarberName("Barber Name")

      document.querySelector(".content-body").addEventListener('mousedown', handleClickOutside);


      document.addEventListener('touchstart', function (event) {
        touchstartX = event.changedTouches[0].screenX;
        touchstartY = event.changedTouches[0].screenY;
    }, false);

      document.addEventListener('touchend', function (event) {
        touchendX = event.changedTouches[0].screenX;
        touchendY = event.changedTouches[0].screenY;
        handleGesture();
    }, false);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);

    function handleGesture() {
      if (touchendX < touchstartX) {
        dispatch(navtoggle("NAVCLOSE"));
      }
  
      if (touchendX > touchstartX) {

      }
  
      if (touchendY < touchstartY) {
      }
  
      if (touchendY > touchstartY) {
      }
  
      if (touchendY === touchstartY) {
      }
  }

    const handleClickOutside = () => {
      dispatch(navtoggle("NAVCLOSE"));
    }
    
    function ChangeMode(){
      if(background.value==="dark"){
        changeBackground({value:'light', lable:'Light'})
      }else{
        changeBackground({value:'dark', lable:'Dark'})
      }
    }

    function LogoutClicked() {
      setIslogoutButtonLoading(true)
      logout()
            .then((response) => {
                navigate(0);
            // dispatch(loginConfirmedAction(response.data));			              
            })
            .catch((error) => {		
                const errorMessage = formatError(error.response.data);
                console.log(error)
                dispatch(loginFailedAction(errorMessage));
            }).finally((f) => {
              setIslogoutButtonLoading(false)
            })
    }
    
function loginFailedAction(data) {
  return {
      type: LOGIN_FAILED_ACTION,
      payload: data,
  };
}

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: "capitalize" }}
              >
                {finalName.join(" ").length === 0
                  ? "Dashboard"
                  : finalName.join(" ")}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              <li className="nav-item dropdown notification_dropdown">
                  <Link to={"#"} className={`nav-link bell dz-theme-mode p-0 ${ background.value ==="dark" ? 'active' : ''}`}
                    onClick={ChangeMode}
                  >
                      <i id="icon-light" className="fas fa-sun"></i>
                      <i id="icon-dark" className="fas fa-moon"></i>
                  </Link>
							</li>
              <Dropdown as="li" className="nav-item header-profile">
                <Dropdown.Toggle
                  as="a"
                  to="#"
                  variant=""
                  className="nav-link i-false c-pointer"
                >
                  <div className="header-info">
                    <span className="text-black">
                      {barberName === null ? <span>...</span> : <strong>{barberName}</strong>}
                    </span>
                    <p className="fs-12 mb-0">Barber</p>
                  </div>
                  <img src={profile} width={20} alt="" />
                </Dropdown.Toggle>
                <Dropdown.Menu align="right" className="mt-2">
                  <Link to="/app-profile" className="dropdown-item ai-icon">
                    <svg
                      id="icon-user1"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-primary"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                      <circle cx={12} cy={7} r={4} />
                    </svg>
                    <span className="ms-2">Profile </span>
                  </Link>
                  <Link to="/email-inbox" className="dropdown-item ai-icon">
                    <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                    <span className="ms-2">Inbox </span>
                  </Link>
                  {isLogoutButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                  :
                  <Link className="dropdown-item ai-icon"
                  onClick={() => {LogoutClicked(navigate);}}
                  >
                    <svg
                      id="icon-logout"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-danger"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                      <polyline points="16 17 21 12 16 7" />
                      <line x1={21} y1={12} x2={9} y2={12} />
                    </svg>
                    <span className="ms-2">Logout</span>
                  </Link>}
                </Dropdown.Menu>
              </Dropdown>
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Header;
