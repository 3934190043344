import React, { useState } from 'react'
import { Link,useNavigate } from 'react-router-dom'
import { connect, useDispatch } from 'react-redux';
import { formatError, signUp } from '../../services/AuthService';
import Swal from "sweetalert2";
import login from "../../images/auth-bg-left.jpg";
import loginbg from "../../images/auth-bg.jpg";
import { loadingToggleAction } from '../../store/actions/AuthActions';
import { Password } from '@mui/icons-material';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';

function Register(props) {
    const [email, setEmail] = useState('');
    const [barberShopName, setBarberShopName] = useState('');
    const [isRegisterButtonLoading, setIsRegisterButtonLoading] = useState(false);
    let errorsObj = { email: '', password: '', confirmPassword: '', barberShopName: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function onSignUp(e) {
        e.preventDefault();
        const error = ValidateSignUpData(e);

        if (error){
          return;
        } else {
          setIsRegisterButtonLoading(true)
        }
        dispatch(loadingToggleAction(true));

          signUp(email, password, barberShopName)
          .then((response) => {
              Swal.fire({
                  icon: 'success',
                  title: 'Account Created',
                  text: "Go ahead and login to your new account",                        
              })
              navigate('/login');
          })
          .catch((error) => {
              if (error.response === undefined || error.response === null) {
                  Swal.fire({
                      icon: 'error',
                      title: 'Oops',
                      text: 'Something went wrong, please try again',                        
                  })
              } else {
                  const errorMessage = formatError(error.response.data);
                  dispatch(signupFailedAction(errorMessage));
              }
          }).finally((f) => {
            setIsRegisterButtonLoading(false)
          })
    }

    function signupFailedAction(message) {
      return {
          type: SIGNUP_FAILED_ACTION,
          payload: message,
      };
  }

    function ValidateSignUpData(e) {
      let error = false;
      const errorObj = { ...errorsObj };
      if (email === '') {
          errorObj.email = 'Email is required';
          error = true;
      }

      if (password === '') {
          errorObj.password = 'Password is required';
          error = true;
      }

      if (password.length < 8) {
        errorObj.password = 'Minimum password length is 8 characters';
        error = true;
    }

    if (confirmPassword === '') {
      errorObj.confirmPassword = 'Password is required';
      error = true;
  } else{
    if (password !== confirmPassword)
      {
        errorObj.confirmPassword = 'Password does not match'
        error = true;
      }
  }


      if (barberShopName === '') {
        errorObj.barberShopName = 'A barber shop name is required'
        error = true
      }

      setErrors(errorObj);

      return error
    }

  return (
    <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
    <div className="login-wrapper">
        <div className="login-aside-left" style={{backgroundImage:"url("+ login +")"}}>
            {/* <Link to="/dashboard" className="login-logo">
                <img src={logo} alt="" className="me-2"/>
                <img src={logotext} alt="" className="ms-1"/>
              </Link> */}
            <div className="">
                <h2 className="title mt-5">Register</h2>
            </div>
        </div>
        <div className="login-aside-right">
            <div className="row m-0 justify-content-center h-100 align-items-center">
              <div className="col-xl-7 col-xxl-7">
                <div className="authincation-content">
                  <div className="row no-gutters">
                    <div className="col-xl-12">
                      <div className="auth-form-1">
                        <div className="mb-4">
                            <h4 className="text-white mb-1">Register an account here</h4>
                            {/* <p className="text-white">Register an account here</p> */}
                        </div>
                        {props.errorMessage && (
                            <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                {props.errorMessage}
                            </div>
                        )}
                        {props.successMessage && (
                            <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                {props.successMessage}
                            </div>
                        )}
                        <form onSubmit={onSignUp}>
                        <div className="form-group">
                                <label className="mb-2 ">
                                  <strong className="text-white">Barber shop name</strong>
                                </label>
                                <input
                                  placeholder='Name' 
                                  className="form-control"
                                  value={barberShopName}
                                  onChange={(e) => setBarberShopName(e.target.value)}
                                />
                              {errors.barberShopName && <div className="text-danger fs-12">{errors.barberShopName}</div>}
                            </div>
                            <div className="form-group">
                                <label className="mb-2 ">
                                  <strong className="text-white">Email</strong>
                                </label>
                                <input type="email" className="form-control"
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  placeholder='Email'
                                />
                              {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                            </div>
                            <div className="form-group">
                                <label className="mb-2 "><strong className="text-white">Password</strong></label>
                                <input
                                  type="password"
                                  className="form-control"
                                  value={password}
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                    placeholder='Password'
                                />
                                {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                            </div>
                            <div className="form-group">
                                <label className="mb-2 "><strong className="text-white">Confirm Password</strong></label>
                                <input
                                  type="password"
                                  className="form-control"
                                  value={confirmPassword}
                                    onChange={(e) =>
                                      setConfirmPassword(e.target.value)
                                    }
                                    placeholder='Confirm Password'
                                />
                                {errors.confirmPassword && <div className="text-danger fs-12">{errors.confirmPassword}</div>}
                            </div>
                          <div className="mt-4 text-center">
                                    <button
                                      type="submit"
                                      className="btn bg-white text-primary d-block w-100"
                                      disabled={isRegisterButtonLoading}
                                    >
                                      {isRegisterButtonLoading ?  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> : <span>Sign up</span>}
                                    </button>
                                  </div>
                        </form>
                        <div className="new-account mt-2">
                          <p className="text-white">
                            Already have an account?{" "}
                            <Link  className="text-white" to="/login">
                              <u>Sign in</u>
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>
  )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(Register);
