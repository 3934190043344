import PageTitle from "../../../layouts/PageTitle";


const EditHomeScreen = () => {
    return (
        <div className="h-80">
        <PageTitle activeMenu="Edit Home Screen" motherMenu="Barber Shop" />
        </div>
    );
}

export default EditHomeScreen;