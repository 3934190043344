import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { Link,useNavigate } from 'react-router-dom'
import { loadingToggleAction } from '../../store/actions/AuthActions';
import Swal from "sweetalert2";
import { formatError, login } from '../../services/AuthService';

// import logo from '../../images/logo.png'
// import logotext from '../../images/logo-text.png'
import loginImg from "../../images/auth-bg-left.jpg";
import loginbg from "../../images/auth-bg.jpg";
export const LOGIN_FAILED_ACTION = '[login action] failed login';

function Login (props) {
    const [email, setEmail] = useState('');
    const [isSignInButtonLoading, setIsSignInButtonLoading] = useState(false)
    let errorsObj = { email: '', password: '' };
    const [errors, setErrors] = useState(errorsObj);
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    async function onLogin(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
        if (email === '') {
            errorObj.email = 'Email is Required';
            error = true;
        }
        if (password === '') {
            errorObj.password = 'Password is Required';
            error = true;
        }
        setErrors(errorObj);
        if (error) {
			return;
		} else {
      setIsSignInButtonLoading(true)
    }
		dispatch(loadingToggleAction(true));	
    // dispatch(loginAction(email, password, navigate));

      login(email, password)
          .then((response) => {
              navigate('/');
              navigate(0);
          })
          .catch((error) => {		
              if (error.response === undefined || error.response === null) {
                  Swal.fire({
                      icon: 'error',
                      title: 'Oops',
                      text: 'Something went wrong, please try again',                        
                  })
              } else {
              const errorMessage = formatError(error.response.data);
              dispatch(loginFailedAction(errorMessage));
              }
          }).finally((t) => {
            setIsSignInButtonLoading(false)
          })
    }

    function loginFailedAction(data) {
      return {
          type: LOGIN_FAILED_ACTION,
          payload: data,
      };
  }

  return (

        <div className="login-main-page" style={{backgroundImage:"url("+ loginbg +")"}}>
            <div className="login-wrapper">
                <div className="login-aside-left" style={{backgroundImage:"url("+ loginImg +")"}}>
                    {/* <Link to="/dashboard" className="login-logo">
                        <img src={logo} alt="" className="me-2"/>
                        <img src={logotext} alt="" className="ms-1"/>
                      </Link> */}
            <div className="">
                <h2 className="title mt-5">Sign in</h2>
            </div>
                </div>
                <div className="login-aside-right">
                    <div className="row m-0 justify-content-center h-100 align-items-center">
                      <div className="col-xl-7 col-xxl-7">
                        <div className="authincation-content">
                          <div className="row no-gutters">
                            <div className="col-xl-12">
                              <div className="auth-form-1">
                                <div className="mb-4">
                                    <h4 className="text-white mb-1">Sign in to your account here</h4>
                                    {/* <p className="text-white">Sign in by entering information below</p> */}
                                </div>
                                {props.errorMessage && (
                                    <div className='bg-red-300 text-red-900 border border-red-900 p-1 my-2'>
                                        {props.errorMessage}
                                    </div>
                                )}
                                {props.successMessage && (
                                    <div className='bg-green-300 text-green-900 border border-green-900 p-1 my-2'>
                                        {props.successMessage}
                                    </div>
                                )}
                                <form onSubmit={onLogin}>
                                    <div className="form-group">
                                        <label className="mb-2 ">
                                          <strong className="text-white">Email</strong>
                                        </label>
                                        <input type="email" className="form-control"
                                          value={email}
                                          onChange={(e) => setEmail(e.target.value)}
                                          placeholder='Email'
                                        />
                                      {errors.email && <div className="text-danger fs-12">{errors.email}</div>}
                                    </div>
                                    <div className="form-group">
                                        <label className="mb-2 "><strong className="text-white">Password</strong></label>
                                        <input
                                          type="password"
                                          className="form-control"
                                          value={password}
                                            onChange={(e) =>
                                                setPassword(e.target.value)
                                            }
                                            placeholder='Password'
                                        />
                                        {errors.password && <div className="text-danger fs-12">{errors.password}</div>}
                                    </div>
                                  <div className="mt-4 text-center">
                                    <button
                                      type="submit"
                                      className="btn bg-white text-primary d-block w-100"
                                      disabled={isSignInButtonLoading}
                                    >
                                      {isSignInButtonLoading ?  <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> : <span>Sign In</span>}
                                    </button>
                                  </div>
                                </form>
                                <div className="new-account mt-2">
                                  <p className="text-white">
                                    Don't have an account?{" "}
                                    <Link className="text-white" to="/page-register">
                                      <u>Register</u>
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        errorMessage: state.auth.errorMessage,
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};
export default connect(mapStateToProps)(Login);