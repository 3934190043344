import PageTitle from "../../../layouts/PageTitle";


const TimeOff = () => {
    return (
        <div className="h-80">
        <PageTitle activeMenu="Time Off" motherMenu="" />
        </div>
    );
}

export default TimeOff;