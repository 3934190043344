export const Register = 'Register';
export const ValidateLogin = 'Validate-Login';
export const Logout = 'Logout';
export const Authenticated = 'Authenticated';
export const Services = "services";
export const Service = "service";
export const ActivateService = "activate-service";
export const DisableService = "disable-service";
export const DeleteService = "remove-service";
export const Barber = "barber";
export const Barbers = "barbers";
export const DeleteBarber = "remove-barber";
export const ActivateBarber = "activate-barber";
export const DisableBarber = "disable-barber";
export const Appointment = "Appointment"
export const Holiday = "Holiday";
export const Events = "Events";
export const ExtremeWorkingTimes = "extreme-working-hours";
export const Customer = "customer";
export const Customers = "customers";
export const UpdateAppointmentTimeSlot = "appointment-slot";
export const UpdateHolidayTimeSlot = "holiday-slot";
export const DeleteHoliday = "holiday-delete";
export const CancelAppointment = "appointment-cancel";
export const BarberWorkingTimes = "barber-working-times";
export const ServiceDurations = "service-durations";



