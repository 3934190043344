import './Calendar.css';
// import '../../../lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import PageTitle from "../../../layouts/PageTitle";
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";
import moment from 'moment';
import { useState, useEffect, useReducer, useCallback } from "react";
import * as React from 'react';
import { useMemo } from 'react';
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ToastContainer} from "react-toastify";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { areIntervalsOverlapping } from "date-fns";
import Divider from '@mui/material/Divider';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import CancelIcon from '@mui/icons-material/Cancel';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import Swal from "sweetalert2";
import {
  GetBarbers,
  GetServices,
} from '../BarberShop/Requests';
import {
  AddAppointment,
  AddHoliday,
  EditHoliday,
  GetEvents,
  EditAppointment,
  AddCustomer,
  GetCustomers,
  UpdateAppointmentTimeSlot,
  UpdateHolidayTimeSlot,
  DeleteHoldiay,
  CancelAppointment
} from './Requests'
import {
  notifySuccess,
  notifyError
} from '../Response/ResposeHandler'
import Select from "react-select";

const localizer = momentLocalizer(moment)
const DnDCalendar = withDragAndDrop(Calendar);

  export const timeSelection = [
    { value: 20, label: "20 mins", isDisabled: false, timeBlockDuration: 1 },
    { value: 40, label: "40 mins", isDisabled: false,  timeBlockDuration: 2 },
    { value: 60, label: "1hr", isDisabled: false,  timeBlockDuration: 3 },
    { value: 80, label: "1hr 20 mins", isDisabled: false,  timeBlockDuration: 4 },
    { value: 100, label: "1hr 40 mins", isDisabled: false,  timeBlockDuration: 5 },
    { value: 120, label: "2hrs", isDisabled: false,  timeBlockDuration: 6 },
  ];

  const daysOfWeek = [
    'Sun',
    'Mon',
    'Tue',
    'Wed',
    'Thur',
    'Fri',
    'Sat'
  ]

  const fullDaysOfWeek = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ]

  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'June',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dev'
  ]

export default function CalendarComponent() {
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [minDateTime, setMinDateTime] = useState()
  const [maxDateTime, setMaxDateTime] = useState()
  const [showCalendar, setShowCalendar] = useState(false)
  const [barberData, setBarberData] = useState(null)
  const [resourceLanes, setResourceLanes] = useState()
  const [barberList, setBarberList] = useState([])
  const today = new Date()
  const [isEditAppointment, setIsEditAppointment] = useState(false)
  const [isSelectedDateToday, setIsSelectedDateToday] = useState(true)
  const [selectedBarber, setSelectedBarber] = useState();
  const [selectedService, setSelectedService] = useState();
  const [startDateTime, setStartDateTime] = useState();
  const [endDateTime, setEndDateTime] = useState();
  const [appointmentNotes, setAppointmentNotes] = useState('')
  const [services, setServices] = useState();
  const [duration, setDuration] = useState();
  const [customerIdToEdit, setCustomerIdToEdit] = useState();
  const [appointmentIdToEdit, setAppointmentIdToEdit] = useState();
  let customerErrorsObj = { customerFirstName: '', customerLastName: '', customerMobile: '', customerEmail: '', customerNotes: '' };
  const [customerErrors, setCustomerErrors] = useState(customerErrorsObj);
  let appointmentErrorsObj = { barber: '', service: '', date: '', duration: '', appointmentNotes: '', customer:'' };
  const [appointmentErrors, setAppointmentErrors] = useState(appointmentErrorsObj);
  let holidayErrorsObj = { holidayTitle: '', holidayBarber: '', holidayStartDateTime: '', holidayEndDateTime: '', holidayRecurrenceFrequency: '', holidayRecurrenceEndDate: '' };
  const [holidayErrors, setHolidayErrors] = useState(holidayErrorsObj);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobile, setMobile] = useState();
  const [email, setEmail] = useState('');
  const [customerNotes, setCustomerNotes] = useState('');
  const [customerBlocked, setCustomerBlocked] = useState();
  const [customerDetailsObject, setCustomerDetailsObject] = useState(null)
  const [selectedBarberForHoliday, setSelectedBarberForHoliday] = useState();
  const [startDateTimeHoliday,setStartDateTimeHoliday] = useState();
  const [endDateTimeHoliday,setEndDateTimeHoliday] = useState();
  const [isRecurringEvent, setIsRecurringEvent] = useState(false);
  const [isSelectedEventRecurring, setIsselectedEventRecurring] = useState(false)
  const [recurringFrequency, setRecurringFrequency] = useState();
  const [endReccuranceDate, setEndRecuranceDate] = useState();
  const [holidayTitle, setHolidayTitle] = useState();
  const [eventData, setEventData] = useState();
  const [isAccorOpen, setIsAcorOpen] = useState(false)
  const [searchCustomerKeyword, setSearchCustomerKeyword] = useState()
  const [searchForCustomerError, setSearchForCustomerError] = useState()
  const [customerList, setCustomerList] = useState ([])
  const [isCustomerSelectionOpen, setIsCustomerSelectionOpen] = useState(false)
  const [isHolidayEdit, setIsHolidayEdit] = useState(false)
  const [holidayToEdit, setHolidayToEdit] = useState();
  const [changeHolidaySlotEvent, setChangeHolidaySlotEvent] = useState();
  const [isAppointmentCancelled, setIsAppointmentCancelled] = useState();
  const [appointmentCancelledByMessage, setAppointmentCancelledByMessage] = useState();
  useEffect(() => {
    GetServicesWrapper()
}, []);

const initialState = false;

const reducer = (state, action) =>{
  switch (action.type){
      case 'addEventOptionsOpen':
          return { ...state, addEventOptions: true }
      case 'addEventOptionsClose':			
          return { ...state, addEventOptions: false }
      case 'AddAppointmentOpen':
          return { ...state, addAppointment: true }
      case 'AddAppointmentClose':			
          return { ...state, addAppointment: false }
      case 'addCustomerOpen':
        return { ...state, addCustomer: true }
      case 'addCustomerClose':			
        return { ...state, addCustomer: false }
      case 'addHolidayOpen':
        return { ...state, addHoliday: true }
      case 'addHolidayClose':			
        return { ...state, addHoliday: false }
      case 'editHolidayOptionsOpen':
        return { ...state, editHolidayOptions: true }
      case 'editHolidayOptionsClose':			
        return { ...state, editHolidayOptions: false }
      case 'editHolidayOptionsOnHolidayTimeSlotChangeOpen':
        return { ...state, editHolidayOptionsOnHolidayTimeSlotChange: true }
      case 'editHolidayOptionsOnHolidayTimeSlotChangeClose':			
        return { ...state, editHolidayOptionsOnHolidayTimeSlotChange: false }
      case 'deleteHolidayOptionsOpen':
        return { ...state, deleteHolidayOptions: true }
      case 'deleteHolidayOptionsClose':			
        return { ...state, deleteHolidayOptions: false }
      default:
      return state	
  }	
}
const [state, dispatch] = useReducer(reducer, initialState);

function GetBarbersWrapper(servicesData) {
  setBarberData(null)
  GetBarbers().then((res) => {
    var resouceLanesArray = []
    var barberListArray = []
    res.data.forEach(element => {
      var obj = { value: element.barberId, label: element.name, isDisabled: element.status === 1 ? false : true }
      barberListArray.push(obj)
      var resouce = { value: element.barberId, label: element.name }
      resouceLanesArray.push(resouce);
    });
      setResourceLanes(resouceLanesArray)
      setBarberList(barberListArray)
      setBarberData(res.data)
      GetEventsWrapper(res.data, new Date(), resouceLanesArray, servicesData)
  }).catch((error) => {
      notifyError("Unable to retrieve barbers, please try again")
  })
}

function GetEventsWrapper(barbers, queryDate, resourceLanesData, services) {
  console.log('resoucelane' + resourceLanesData)
  //setting the min and start times to show on the calendar
  var starts = []
  var ends = []
  resourceLanesData.forEach((barber) => {
    const day = fullDaysOfWeek[new Date(queryDate).getDay()]
    var barberToCheck = barbers.filter(x => x.barberId === barber.value)
    var startTime = barberToCheck[0][day].start
    var endTime = barberToCheck[0][day].end
    if (startTime !== null && endTime !== null)
    {
      var startHour = parseInt(startTime.split(':')[0])
      var endHour = parseInt(endTime.split(':')[0])
      starts.push(startHour)
      ends.push(endHour)
      // console.log(starts)
      // console.log(ends)
      // setMinDateTime(new Date().setHours(Math.min.apply(0, starts),0,0,0))
      // setMaxDateTime(new Date().setHours(Math.max.apply(0, ends),0,0,0))
    } else{
      // setMinDateTime(new Date().setHours(0,0,0,0))
      // setMaxDateTime(new Date().setHours(23,59,0,0))
    }
  })

  var minStart = new Date(queryDate).setHours(0,0,0,0)
  var maxEnd = new Date(queryDate).setHours(23,59,0,0)
  if (starts.length > 0 && ends.length > 0) {
      minStart = new Date(queryDate).setHours(Math.min.apply(0, starts),0,0,0)
      maxEnd = new Date(queryDate).setHours(Math.max.apply(0, ends),0,0,0)
      setMinDateTime(minStart)
      setMaxDateTime(maxEnd)
  } else {

      setMinDateTime(minStart)
      setMaxDateTime(maxEnd)
  }

  if (new Date(queryDate).setHours(0,0,0,0) === new Date(today).setHours(0,0,0,0)) {
    setIsSelectedDateToday(true)
  } else {
    setIsSelectedDateToday(false)
  }
  setSelectedDate(queryDate);

  //setting calendar blocks
  var eventss = []
  barbers.forEach((barber, index) => {
    const day = fullDaysOfWeek[new Date(queryDate).getDay()]
    var startTime = barber[day].start
    const endTime = barber[day].end

    if (startTime !== null && endTime !== null)
    {
      if (parseInt(startTime.split(':')[0]) !== Math.min.apply(0, starts))
      {
        var eventt = {
          id : index,
          eventId: index,
          eventType: 'CalendarBlockOut',
          title: '5',
          start: new Date(new Date(queryDate).setHours(0,0,0,0)),
          end: new Date(new Date(queryDate).setHours(parseInt(startTime.split(':')[0]),0,0,0)),
          resourceId: barber.barberId,
          isCalanderBlock: true,
          isResizable: false,
          isDraggable: false,
          colorEvento: '#dedede'
        }
        eventss.push(eventt)
      }
      if (parseInt(endTime.split(':')[0]) !== Math.max.apply(0, ends))
      {
        var eventt = {
          id : index,
          eventId: index,
          eventType: 'CalendarBlockOut',
          title: '',
          start:new Date(new Date(queryDate).setHours(parseInt(endTime.split(':')[0]),0,0,0)), 
          end: new Date(new Date(queryDate).setHours(23,40,0,0)),
          resourceId: barber.barberId,
          isCalanderBlock: true,
          isResizable: false,
          isDraggable: false,
          colorEvento: '#dedede'
        }
        eventss.push(eventt)
      }

    } else {
      var eventt = {
        id : index,
        eventId: index,
        eventType: 'CalendarBlockOut',
        title: 'null',
        start: new Date(new Date(queryDate).setHours(0,0,0,0)),
        end: new Date(new Date(queryDate).setHours(23,59,0,0)),
        resourceId: barber.barberId,
        isCalanderBlock: true,
        isResizable: false,
        isDraggable: false,
        colorEvento: '#dedede'
      }
      eventss.push(eventt)
    }

  })

  //getting events from DB and setting them as calendar events
  GetEvents(queryDate).then((res) => {
    res.data.forEach((event) => {
      var color = barbers.filter(x => x.barberId === event.barberId)[0].barberColour
      console.log(color)
      // console.log(new Date(event.startDateTime))
      // console.log(new Date((new Date().setHours(Math.min.apply(0, starts),0,0,0))))
      var t = new Date(event.startDateTime).getTime() <= new Date(minStart).getTime() && new Date(event.endDateTime).getTime() >= new Date(maxEnd).getTime()
      console.log(t)
      var eventt = {
        id : event.eventId,
        eventId: event.eventId,
        allDay:false,
        fullDay: t,
        eventType: event.eventType,
        title: event.cancelled ? 'Cancelled' : event.eventType === 3 ? event.title : event.eventType === 2 ? event.title : event.firstName + ' - ' + services.filter(x => x.value === event.serviceId)[0].label,
        serviceId: event.serviceId,
        appointmentNotes: event.appointmentNotes,
        cancelled: event.cancelled,
        cancelledBy: event.cancelledBy,
        cancelledAtUtc: event.cancelledAtUtc,
        customerId: event.customerId,
        firstName: event.firstName,
        lastName: event.lastName,
        phoneNumber: event.phoneNumber,
        email: event.email,
        customerNotes: event.customerNotes,
        customerBlocked: event.blocked,
        start: new Date(event.startDateTime),
        end: new Date(event.endDateTime),
        recurrenceStartDate: event.recurrenceStartDate,
        recurringFrequency: event.recurringFrequency,
        endReccurrenceDate: event.endReccurrenceDate,
        resourceId: event.barberId,
        isCalanderBlock: false,
        isResizable: true,
        isDraggable: !event.cancelled,
        colorEvento: event.cancelled ? 'rgba(190, 190, 190, 0.5)' : event.eventType === 1 ? "#" + color : event.eventType === 2 ?  'pink' : event.eventType === 3 ? 'pink' : 'grey'
      }
      eventss.push(eventt)
    })
    setEventData(eventss)
    console.log(eventss)
  }).catch((error) => {
    console.log(error)
      notifyError("Unable to retrieve events, please try again")
  }).finally((f) => {
    // setShowCalendar(true)
    setShowCalendar(true)
  })
  }

function GetServicesWrapper() {
  GetServices().then((res) => {
      var tempVar = []
      // eslint-disable-next-line
      res.data.map((item, index) => {
          var obj = { value: item.serviceId, label: item.name, duration: item.duration, isDisabled: item.status === 1 ? false : true }
          tempVar.push(obj)
      })
      setServices(tempVar)
      GetBarbersWrapper(tempVar)
  }).catch((error) => {
      notifyError("Unable to retrieve services, please try again")
  })
  }

  const { views, scrollToTime } = useMemo(
    () => ({
      defaultDate: new Date(),
      views: ['day', 'work_week'],
      scrollToTime: moment().add(4, 'hours').toDate()
    }),
    []
  )
  
  const onEventDrop = (e) => {
    console.log(e)
    if (e.start.getTime() === e.event.start.getTime() && e.end.getTime() === e.event.end.getTime() && e.resourceId === e.event.resourceId)
    {
      console.log('same slot')
    } else {
      const calanderBlockOuts = eventData.filter(x => x.eventType === "CalendarBlockOut" && x.resourceId === e.resourceId)
      const clashWithCalanderBlockOuts = eventClashChecker(calanderBlockOuts, e.start, e.end)
      if (clashWithCalanderBlockOuts.length === 0) {
        if (e.event.eventType === 1) {
          const calanderAppointmentsForBarber = eventData.filter(x => x.eventType === 1 && x.resourceId === e.resourceId && x.eventId !== e.event.eventId && !x.cancelled)
          const clashedEvents = eventClashChecker(calanderAppointmentsForBarber, new Date(e.start), new Date(e.end))
          if (clashedEvents.length > 0){
            Swal.fire({
              icon: 'error',
              title: 'Appointment Clash',
              html: `This slot clashes with the following appointments: <b>`
              + clashedEvents.map((clashedEvent) => {return '<br/>' + clashedEvent.title + '&nbsp &nbsp' + ("0" + new Date(clashedEvent.start).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.start).getUTCMinutes()).slice(-2) + ' - ' + ("0" + new Date(clashedEvent.end).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.end).getUTCMinutes()).slice(-2)
            })
          })
          } else {
            const calanderHolidaysForBarber = eventData.filter(x => (x.eventType === 2 || x.eventType === 3)  && x.resourceId === e.resourceId)
            const clashedWithHolidays = eventClashChecker(calanderHolidaysForBarber, new Date(e.start), new Date(e.end))
            if (clashedWithHolidays.length > 0){
              Swal.fire({
                title: 'Are you sure you want to move this appointment',
                text: "You have a holiday during this appointment",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'var(--primary)',
                cancelButtonColor: '#aaa',
                confirmButtonText: 'Change Holiday'
              }).then((result) => {
                if (result.isConfirmed) {
                  UpdateAppointmentTimeSlot(
                    e.event.eventId,
                    e.resourceId,
                    e.start,
                    e.end,
                    ).then((res) => {
                    notifySuccess("Appointment moved")
                    dispatch({type:'AddAppointmentClose'})
                    GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
                    resetAppointmentModal()
                  }).catch((error) => {
                    notifyError("Unable to move appointment, please try again")
                  }).finally((f) => {
                  })
                }
              })
            } else {
            UpdateAppointmentTimeSlot(
              e.event.eventId,
              e.resourceId,
              e.start,
              e.end,
              ).then((res) => {
              notifySuccess("Appointment moved")
              dispatch({type:'AddAppointmentClose'})
              GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
              resetAppointmentModal()
            }).catch((error) => {
              notifyError("Unable to move appointment, please try again")
            }).finally((f) => {
            })
          }
          }
        }

        if (e.event.eventType === 3 && e.resourceId === e.event.resourceId) {
          const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === e.event.resourceId && !x.cancelled)
          const clashWithAppointments = eventClashChecker(barbersEvents, e.start, e.end)
          if (clashWithAppointments.length > 0) {   
            Swal.fire({
              title: 'Are you sure you want to change this holiday',
              text: "You have appointments during this holiday time",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary)',
              cancelButtonColor: '#aaa',
              confirmButtonText: 'Change Holiday'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('moving recurring holiday')
                setChangeHolidaySlotEvent(e)
                dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeOpen'})
              }
            })
      
          } else {
            console.log('moving recurring holiday')
            setChangeHolidaySlotEvent(e)
            dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeOpen'})
          }
        }
        if (e.event.eventType === 2 && e.resourceId === e.event.resourceId) {
          console.log('run')
          const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === e.event.resourceId && !x.cancelled)
          const clashWithAppointments = eventClashChecker(barbersEvents, e.start, e.end)
          console.log(e)
          console.log(clashWithAppointments)
          if (clashWithAppointments.length > 0) {
            Swal.fire({
              title: 'Are you sure you want to change this holiday',
              text: "You have appointments during this holiday time",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary)',
              cancelButtonColor: '#aaa',
              confirmButtonText: 'Change Holiday'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('moving normal holiday')
                console.log('mad')
                changeHolidaySlot(4, e)
              }
            })
      
          } else {
            console.log('moving normal holiday')
            console.log('mad')
            changeHolidaySlot(4, e)
          }
        }
      }
      console.log('different slot')
    }
    console.log(e);
  };

  const changeHolidaySlot = (option, e = null, ) => {
    
    if (e === null) {
      UpdateHolidayTimeSlot(
        changeHolidaySlotEvent.event.eventId,
        changeHolidaySlotEvent.resourceId,
        changeHolidaySlotEvent.start,
        changeHolidaySlotEvent.end,
        option
        ).then((res) => {
        notifySuccess("Holiday moved")
        dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
        resetHolidaysModal()
      }).catch((error) => {
        notifyError("Unable to move Holiday, please try again")
      }).finally((f) => {
      })
    } else {
      UpdateHolidayTimeSlot(
        e.event.eventId,
        e.resourceId,
        e.start,
        e.end,
        option
        ).then((res) => {
        notifySuccess("Holiday moved")
        dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
        resetHolidaysModal()
      }).catch((error) => {
        notifyError("Unable to move Holiday, please try again")
      }).finally((f) => {
      })
    }


  }

  const onEventResize = (e) => {
    console.log(e)
    if (new Date(e.start).getTime() !== new Date(e.end).getTime()){
    if (e.start.getTime() === e.event.start.getTime() && e.end.getTime() === e.event.end.getTime() && e.resourceId === e.event.resourceId)
    {
      console.log('same slot')
    } else {
      const calanderBlockOuts = eventData.filter(x => x.eventType === "CalendarBlockOut" && x.resourceId === e.resourceId)
      const clashWithCalanderBlockOuts = eventClashChecker(calanderBlockOuts, e.start, e.end)
      if (clashWithCalanderBlockOuts.length === 0) {
        if (e.event.eventType === 1) {
          const calanderAppointmentsForBarber = eventData.filter(x => x.eventType === 1 && x.resourceId === e.resourceId && x.eventId !== e.event.eventId && !x.cancelled)
          console.log(calanderAppointmentsForBarber)
          const clashedEvents = eventClashChecker(calanderAppointmentsForBarber, new Date(e.start), new Date(e.end))
          if (clashedEvents.length > 0){
            Swal.fire({
              icon: 'error',
              title: 'Appointment Clash',
              html: `This slot clashes with the following appointments: <b>`
              + clashedEvents.map((clashedEvent) => {return '<br/>' + clashedEvent.title + '&nbsp &nbsp' + ("0" + new Date(clashedEvent.start).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.start).getUTCMinutes()).slice(-2) + ' - ' + ("0" + new Date(clashedEvent.end).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.end).getUTCMinutes()).slice(-2)
            })
          })
          } else {

            const calanderHolidaysForBarber = eventData.filter(x => (x.eventType === 2 || x.eventType === 3)  && x.resourceId === e.resourceId)
            const clashedWithHolidays = eventClashChecker(calanderHolidaysForBarber, new Date(e.start), new Date(e.end))
            if (clashedWithHolidays.length > 0){
              Swal.fire({
                title: 'Are you sure you want to change this appointment',
                text: "You have a holiday during this appointment",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: 'var(--primary)',
                cancelButtonColor: '#aaa',
                confirmButtonText: 'Change Holiday'
              }).then((result) => {
                if (result.isConfirmed) {
                  UpdateAppointmentTimeSlot(
                    e.event.eventId,
                    e.resourceId,
                    e.start,
                    e.end,
                    ).then((res) => {
                    notifySuccess("Appointment moved")
                    dispatch({type:'AddAppointmentClose'})
                    GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
                    resetAppointmentModal()
                  }).catch((error) => {
                    notifyError("Unable to move appointment, please try again")
                  }).finally((f) => {
                  })
                }
              })
            } else {
              UpdateAppointmentTimeSlot(
                e.event.eventId,
                e.resourceId,
                e.start,
                e.end,
                ).then((res) => {
                notifySuccess("Appointment moved")
                dispatch({type:'AddAppointmentClose'})
                GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
                resetAppointmentModal()
              }).catch((error) => {
                notifyError("Unable to move appointment, please try again")
              }).finally((f) => {
              })
            }
          }
        }

        if (e.event.eventType === 3) {
          const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === e.event.resourceId && !x.cancelled)
          const clashWithAppointments = eventClashChecker(barbersEvents, e.start, e.end)
          if (clashWithAppointments.length > 0) {
            //holiday selection clashed with an appointment
            // ask if they are sure
      
            Swal.fire({
              title: 'Are you sure you want to change this holiday',
              text: "You have appointments during this holiday time",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary)',
              cancelButtonColor: '#aaa',
              confirmButtonText: 'Change Holiday'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('moving recurring holiday')
                setChangeHolidaySlotEvent(e)
                dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeOpen'})
              }
            })
      
          } else {
            console.log('moving recurring holiday')
            setChangeHolidaySlotEvent(e)
            dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeOpen'})
          }
        }
        if (e.event.eventType === 2) {
          console.log('run')
          const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === e.event.resourceId && !x.cancelled)
          const clashWithAppointments = eventClashChecker(barbersEvents, e.start, e.end)
          console.log(e)
          console.log(clashWithAppointments)
          if (clashWithAppointments.length > 0) {
            Swal.fire({
              title: 'Are you sure you want to change this holiday',
              text: "You have appointments during this holiday time",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: 'var(--primary)',
              cancelButtonColor: '#aaa',
              confirmButtonText: 'Change Holiday'
            }).then((result) => {
              if (result.isConfirmed) {
                console.log('moving normal holiday')
                console.log('mad')
                changeHolidaySlot(4, e)
              }
            })
      
          } else {
            console.log('moving normal holiday')
            console.log('mad')
            changeHolidaySlot(4, e)
          }
        }
      }
      console.log('different slot')
    }
    }

    console.log(e);
  }

  function addDuration(duration) {
    console.log('changing')
    setDuration(duration)
    if (startDateTime !== undefined && startDateTime !== null)
    {
      var endDateTimeValue = new Date(startDateTime)
      endDateTimeValue.setMinutes(endDateTimeValue.getMinutes() + duration.value)
      setEndDateTime(endDateTimeValue)
    }
  }

  function startDateHandler(e) {
    console.log('changing')
    setStartDateTime(new Date(e))
    if (duration !== undefined)
    {
      var endDateTimeValue = new Date(e)
      endDateTimeValue.setMinutes(endDateTimeValue.getMinutes() + duration.value)
      setEndDateTime(endDateTimeValue)
    } 
  }

  function onClickBarberName(id, name) {
    var check = resourceLanes.filter(x => x.value === id)

    if (check.length === 1)
    {
      var temp = resourceLanes.filter(x => x.value !== id)
      setResourceLanes(temp)
    } else {
      var tempp = { value: id, label: name}
      const newResourceLane = [...resourceLanes, tempp]
      setResourceLanes(newResourceLane)
    }
  }

  function selectServiceHandler(e) {
    setSelectedService({value:e.value ,label: e.label}); 
    setDuration(timeSelection.filter(x => x.timeBlockDuration === e.duration)[0])
    addDuration(timeSelection.filter(x => x.timeBlockDuration === e.duration)[0])
  }

function eventClashChecker (eventsToCheck, startDate, endDate) {
  var clash =  false;
  var clashedEvents = []
  eventsToCheck.forEach((elem)=>{
    const test = !((moment(endDate).diff(moment(elem.start))) <= 0 || 
    (moment(startDate).diff(moment(elem.end))) >= 0);
    if (test) {
      clash = true
      clashedEvents.push(elem)
    }
  })
  return clashedEvents
}

function onSelectSlot(e) {
  resetHolidaysModal()
  console.log('selecting slot')
  const calanderBlockOuts = eventData.filter(x => x.eventType === "CalendarBlockOut" && x.resourceId === e.resourceId)
  const clashWithCalanderBlockOuts = eventClashChecker(calanderBlockOuts, e.start, e.end)
  if (clashWithCalanderBlockOuts.length === 0) {
    const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === e.resourceId)
    const clashWithAppointments = eventClashChecker(barbersEvents, e.start, e.end)
    if (clashWithCalanderBlockOuts.length > 0) {
      //holiday selection clashed with an appointment
      // ask if they are sure

      Swal.fire({
        title: 'Are you sure you want to add a holiday',
        text: "You have appointments during this holiday time",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Create Holiday'
      }).then((result) => {
        if (result.isConfirmed) {
          setStartDateTimeHoliday(dayjs(e.start))
          setEndDateTimeHoliday(dayjs(e.end))
          setSelectedBarberForHoliday(barberList.filter(x => x.value === e.resourceId))
          setIsHolidayEdit(false)
          dispatch({type:'addHolidayOpen'})
        }
      })

    } else {
      console.log(new Date (e.start))
      console.log(new Date (e.end))
      setStartDateTimeHoliday(dayjs(e.start))
      setEndDateTimeHoliday(dayjs(e.end))
      setSelectedBarberForHoliday(barberList.filter(x => x.value === e.resourceId)[0])
      setIsHolidayEdit(false)
      dispatch({type:'addHolidayOpen'})
    }
  }
}

function onSelectEvent(e) {
  console.log(e)
  if (e.eventType === 1) {
    setIsAppointmentCancelled(e.cancelled)
    setAppointmentCancelledByMessage(e.cancelled ? 'Cancelled by ' + e.cancelledBy + ' on ' + new Date(e.cancelledAtUtc).toDateString() +' at ' + new Date(e.cancelledAtUtc).getHours() + ':' + new Date(e.cancelledAtUtc).getMinutes() : null)
    setIsEditAppointment(true)
    setSelectedBarber(barberList.filter(x => x.value === e.resourceId)[0])
    setSelectedService(services.filter(x => x.value === e.serviceId)[0]); 
    setStartDateTime(dayjs(e.start))
    setEndDateTime(dayjs(e.end))
    setDuration(timeSelection.filter(x => x.value === (new Date(e.end).getTime() - new Date(e.start).getTime())/60000)[0])
    setAppointmentNotes(e.appointmentNotes)
    setFirstName(e.firstName)
    setLastName(e.lastName)
    setMobile(e.phoneNumber)
    setEmail(e.email)
    setCustomerNotes(e.customerNotes)
    setCustomerBlocked(e.customerBlocked)
    var newCustomerObject = {firstName:e.firstName, lastName:e.lastName, mobile:e.phoneNumber, email:e.email, notes:e.customerNotes, isBlocked:e.customerBlocked}
    setCustomerDetailsObject(newCustomerObject)
    setCustomerIdToEdit(e.customerId)
    setAppointmentIdToEdit(e.eventId)
    dispatch({type:'AddAppointmentOpen'})
  }

  if (e.eventType === 2 || e.eventType === 3) {
    setIsHolidayEdit(true)
    setHolidayToEdit(e.eventId)
    setHolidayTitle(e.title)
    setSelectedBarberForHoliday(barberList.filter(x => x.value === e.resourceId)[0])
    setStartDateTimeHoliday(dayjs(e.start))
    setEndDateTimeHoliday(dayjs(e.end))
    setIsRecurringEvent(e.recurringFrequency !== null ? true: false)
    setIsselectedEventRecurring(e.recurringFrequency !== null ? true: false)
    setRecurringFrequency(e.recurringFrequency)
    setEndRecuranceDate(dayjs(e.endReccurrenceDate))
    dispatch({type:'addHolidayOpen'})
  }

}

function onDeleteHoliday(deleteOption) {
  Swal.fire({
    title: 'Are you sure you want to delete this holiday',
    // text: "You have appointments during this holiday time",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'var(--primary)',
    cancelButtonColor: '#aaa',
    confirmButtonText: 'Delete'
  }).then((result) => {
    if (result.isConfirmed) {
      DeleteHoldiay(
        holidayToEdit,
        eventData.filter(x => x.eventId === holidayToEdit)[0].start,
        deleteOption,
        ).then((res) => {
        notifySuccess("Holiday deleted")
        dispatch({type:'deleteHolidayOptionsClose'})
        dispatch({type:'addHolidayClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
      }).catch((error) => {
        notifyError("Unable to delete holiday, please try again")
      }).finally((f) => {
      })  
    }
  })
}

function onCancelAppointment(cancelAppointmentOption) {
  Swal.fire({
    title: cancelAppointmentOption === 0 ? 'Are you sure you want to cancel this appointment' : 'Are you sure you want to reinstate this appointment',
    // text: "You have appointments during this holiday time",
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: 'var(--primary)',
    cancelButtonColor: '#aaa',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  }).then((result) => {
    if (result.isConfirmed) {
      const cancelAppointmentBy = "Barber"
      CancelAppointment(
        appointmentIdToEdit,
        cancelAppointmentOption,
        cancelAppointmentBy
        ).then((res) => {
        notifySuccess("Appointment cancelled")
        dispatch({type:'AddAppointmentClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
      }).catch((error) => {
        notifyError("Unable to cancel appointment, please try again")
      }).finally((f) => {
      })  
    }
  })
}

  function resetCustomerModal() {
    setFirstName('')
    setLastName('')
    setMobile()
    setEmail('')
    setCustomerNotes('')
    setCustomerErrors(customerErrorsObj)
    setCustomerBlocked()
    setSearchForCustomerError()
    setIsCustomerSelectionOpen(false)
    setCustomerList([])
  }

  function resetAppointmentModal() {
    resetCustomerModal()
    setSelectedBarber()
    setSelectedService()
    setStartDateTime()
    setEndDateTime()
    setDuration()
    setAppointmentNotes('')
    setAppointmentErrors(appointmentErrorsObj)
    setCustomerDetailsObject(null)
  }

  function resetHolidaysModal() {
    setHolidayTitle()
    setSelectedBarberForHoliday()
    setStartDateTimeHoliday()
    setEndDateTimeHoliday()
    setIsRecurringEvent(false)
    setRecurringFrequency()
    setEndRecuranceDate()
    setHolidayErrors(holidayErrorsObj)
  }

  function loadCustomerDetails() {
    setFirstName(customerDetailsObject.firstName)
    setLastName(customerDetailsObject.lastName)
    setEmail(customerDetailsObject.email)
    setMobile(customerDetailsObject.mobile)
    setCustomerNotes(customerDetailsObject.notes)
    setCustomerBlocked(customerDetailsObject.isBlocked)
    setCustomerErrors(customerErrorsObj)
  }

  function OnCustomerSubmitClick(e)
 {
  e.preventDefault();
  let error = false;
  const customerErrorObj = { ...customerErrorsObj };

  if (firstName === '') {
    customerErrorObj.customerFirstName = 'First name is required';
    error = true;
  }

  if (lastName === '') {
    customerErrorObj.customerLastName = 'Last name is required';
    error = true;
  }

  if(mobile !== undefined && mobile !== null)
  {
  if (mobile.length > 0) {
    if (mobile.length !== 11)
    {
      customerErrorObj.customerMobile = 'Invalid mobile number';
      error = true;
    }
  }
}

  if (email === '') {
    customerErrorObj.customerEmail = 'Email is required';
    error = true;
  } 

  setCustomerErrors(customerErrorObj);
  if (error) {
return;
} else {

  // add customer
  AddCustomer(
    firstName,
    lastName,
    mobile,
    email,
    customerNotes,
    customerBlocked,
    ).then((res) => {
    notifySuccess("Customer Saved")
    var newCustomerObject = {customerId: res.data, firstName:firstName, lastName:lastName, mobile:mobile, email:email, notes:customerNotes, isBlocked:customerBlocked}
    setCustomerDetailsObject(newCustomerObject)
    dispatch({type:'addCustomerClose'})
    setCustomerList([])
    GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
  }).catch((error) => {
    notifyError("Unable to create customer, please try again")
  }).finally((f) => {
  })
}
}

 function onSubmitAppointmentClick(e) {
  // e.preventDefault();
  let error = false;
  const appointmentErrors = { ...appointmentErrorsObj };

  if (selectedBarber === undefined || selectedBarber === null) {
    appointmentErrorsObj.barber = 'Required';
    error = true;
  }

  if (selectedService === undefined || selectedService === null) {
    appointmentErrorsObj.service = 'Required';
    error = true;
  }

  if (startDateTime === undefined || startDateTime === null) {
    appointmentErrorsObj.date = 'Required';
    error = true;
  }
  console.log(duration)
  if (duration === undefined || duration === null) {
    appointmentErrorsObj.duration = 'Required';
    error = true;
  }
  if (customerDetailsObject === null)
  {
    appointmentErrorsObj.customer = "Add a customer"
    error = true
  }

  setAppointmentErrors(appointmentErrorsObj);
  if (error) {
return;
} else {
  const calanderAppointmentsForBarber = eventData.filter(x => x.eventType === 1 && x.resourceId === selectedBarber.value && x.eventId !== appointmentIdToEdit)
  console.log(appointmentIdToEdit)
  // console.log(calanderAppointmentsForBarber)
  const clashedEvents = eventClashChecker(calanderAppointmentsForBarber, new Date(startDateTime), new Date(endDateTime))
  console.log(clashedEvents)
  if (clashedEvents.length > 0){
    Swal.fire({
      icon: 'error',
      title: 'Appointment Clash',
      html: `This slot clashes with the following appointments: <b>`
      + clashedEvents.map((clashedEvent) => {return '<br/>' + clashedEvent.title + '&nbsp &nbsp' + ("0" + new Date(clashedEvent.start).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.start).getUTCMinutes()).slice(-2) + ' - ' + ("0" + new Date(clashedEvent.end).getUTCHours()).slice(-2) + ':' + ("0" + new Date(clashedEvent.end).getUTCMinutes()).slice(-2)
    })
  })

  } else {
    const createdBy = "Barber Shop";

    const calanderHolidaysForBarber = eventData.filter(x => (x.eventType === 2 || x.eventType === 3)  && x.resourceId === selectedBarber.value)
    const clashedWithHolidays = eventClashChecker(calanderHolidaysForBarber, new Date(startDateTime), new Date(endDateTime))
    if (clashedWithHolidays.length > 0){
      Swal.fire({
        title: isEditAppointment ? 'Are you sure you want to edit this appointment' : 'Are you sure you want to add this appointment',
        text: "You have a holiday during this appointment",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--primary)',
        cancelButtonColor: '#aaa',
        confirmButtonText: 'Change Holiday'
      }).then((result) => {
        if (result.isConfirmed) {
          if (isEditAppointment) {
            console.log(duration)
            EditAppointment(
              appointmentIdToEdit,
              customerIdToEdit,
              selectedBarber.value,
              selectedService.value,
              startDateTime,
              endDateTime,
              duration.timeBlockDuration,
              appointmentNotes,
              customerDetailsObject.firstName,
              customerDetailsObject.lastName,
              customerDetailsObject.mobile,
              customerDetailsObject.email,
              customerDetailsObject.notes,
              customerDetailsObject.isBlocked,
              createdBy
              ).then((res) => {
              notifySuccess("Appointment Edited")
              dispatch({type:'AddAppointmentClose'})
              GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
              resetAppointmentModal()
            }).catch((error) => {
              notifyError("Unable to add appointment, please try again")
            }).finally((f) => {
              setIsEditAppointment(false)
            })
          } else {
            AddAppointment(
              selectedBarber.value,
              customerDetailsObject.customerId,
              selectedService.value,
              startDateTime,
              endDateTime,
              duration.timeBlockDuration,
              appointmentNotes,
              createdBy
              ).then((res) => {
              notifySuccess("Appointment Added")
              dispatch({type:'AddAppointmentClose'})
              GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
              resetAppointmentModal()
            }).catch((error) => {
              notifyError("Unable to add appointment, please try again")
            }).finally((f) => {
            })
          }
        }
      })
    } else {
    if (isEditAppointment) {
      console.log(duration)
      EditAppointment(
        appointmentIdToEdit,
        customerIdToEdit,
        selectedBarber.value,
        selectedService.value,
        startDateTime,
        endDateTime,
        duration.timeBlockDuration,
        appointmentNotes,
        customerDetailsObject.firstName,
        customerDetailsObject.lastName,
        customerDetailsObject.mobile,
        customerDetailsObject.email,
        customerDetailsObject.notes,
        customerDetailsObject.isBlocked,
        createdBy
        ).then((res) => {
        notifySuccess("Appointment Edited")
        dispatch({type:'AddAppointmentClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
        resetAppointmentModal()
      }).catch((error) => {
        notifyError("Unable to add appointment, please try again")
      }).finally((f) => {
        setIsEditAppointment(false)
      })
    } else {
      AddAppointment(
        selectedBarber.value,
        customerDetailsObject.customerId,
        selectedService.value,
        startDateTime,
        endDateTime,
        duration.timeBlockDuration,
        appointmentNotes,
        createdBy
        ).then((res) => {
        notifySuccess("Appointment Added")
        dispatch({type:'AddAppointmentClose'})
        GetEventsWrapper(barberData, selectedDate, resourceLanes, services)
        resetAppointmentModal()
      }).catch((error) => {
        notifyError("Unable to add appointment, please try again")
      }).finally((f) => {
      })
    }
  }
  }
}
}

function searchForCustomer() {
  if (searchCustomerKeyword === null || searchCustomerKeyword === undefined || searchCustomerKeyword === "") {
    setSearchForCustomerError("Enter a search keyword")
  } else {
    setSearchForCustomerError()

    GetCustomers(
      searchCustomerKeyword
      ).then((res) => {
        console.log(res.data)
        if (res.data.length > 0)
        {
          var customerSelectionList = [];
          res.data.forEach(customer => {
            var customerSelection = {value: customer.customerId, label: customer.customerEmail, firstName: customer.customerFirstName, lastName: customer.customerLastName, mobile: customer.customerMobile, notes: customer.customerNotes, isBlocked: customer.customerIsBlocked}
            customerSelectionList.push(customerSelection)
          })
          setCustomerList(customerSelectionList)
          setIsCustomerSelectionOpen(true)
        } else {
          setSearchForCustomerError("No customers not found")
        }
    }).catch((error) => {
      notifyError("Unable to search for customers, please try again")
    }).finally((f) => {
    })
  }
}

function selectCustomerFromCustomerSelectionList(customer) {
  setFirstName(customer.firstName)
  setLastName(customer.lastName)
  setMobile(customer.mobile)
  setEmail(customer.label)
  setCustomerNotes(customer.notes)
  setCustomerBlocked(customer.isBlocked)
}

function OnHolidaySubmitClick(e, editOption) {
  e.preventDefault();
  let error = false;
  // const holidayErrorsObj = { ...holidayErrorsObj };
  const holidayErrorOnj = { ...holidayErrorsObj };

  if (holidayTitle === "" || holidayTitle === null || holidayTitle === undefined){
    holidayErrorsObj.holidayTitle = 'Required';
    error = true;
  }

  if (selectedBarberForHoliday === undefined || selectedBarberForHoliday === null)
  {
    holidayErrorsObj.holidayBarber = 'Required';
    error = true;
  }

  if(startDateTimeHoliday === undefined || startDateTimeHoliday === null)
  {
    holidayErrorsObj.holidayStartDateTime = 'Required';
    error = true;
  }

  if(endDateTimeHoliday === undefined || endDateTimeHoliday === null)
  {
    holidayErrorsObj.holidayEndDateTime = 'Required';
    error = true;
  }

  if (endDateTimeHoliday !== undefined && startDateTimeHoliday !== undefined)
  {
    if (endDateTimeHoliday <= startDateTimeHoliday)
    {
      holidayErrorsObj.holidayEndDateTime = 'Invalid';
      error = true;
    }
  }

  if (isRecurringEvent)
  {
    if (recurringFrequency === undefined || recurringFrequency === null)
    {
      holidayErrorsObj.holidayRecurrenceFrequency = 'Required';
      error = true;
    }
  }

  if (endReccuranceDate !== undefined || endReccuranceDate !== null)
  {
    if(new Date(endReccuranceDate) < new Date(startDateTimeHoliday).setHours(0,0,0,0))
    {
      holidayErrorsObj.holidayRecurrenceEndDate = 'Invalid';
      error = true;
    }
  }

  setHolidayErrors(holidayErrorsObj);
  if (error) {
return;
} else {

  const barbersEvents = eventData.filter(x => x.eventType === 1 && x.resourceId === selectedBarberForHoliday.value && !x.cancelled)
  console.log(barbersEvents)
  console.log(startDateTimeHoliday)
  const clashWithAppointments = eventClashChecker(barbersEvents, new Date(startDateTimeHoliday), new Date(endDateTimeHoliday))
  // console.log(clashWithAppointments)
  if (clashWithAppointments.length > 0) {

    Swal.fire({
      title: isHolidayEdit ? 'Are you sure you want to edit this holiday' : 'Are you sure you want to add this holiday',
      text: "You have appointments during this holiday time",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'var(--primary)',
      cancelButtonColor: '#aaa',
      confirmButtonText: 'Change Holiday'
    }).then((result) => {
      if (result.isConfirmed) {
        if (isHolidayEdit) {
          console.log(holidayToEdit)
          EditHoliday(
            holidayToEdit,
            holidayTitle,
            selectedBarberForHoliday.value,
            startDateTimeHoliday,
            endDateTimeHoliday,
            isRecurringEvent,
            recurringFrequency,
            endReccuranceDate,
            editOption
            ).then((res) => {
            notifySuccess("Holiday Edited")
            dispatch({type:'editHolidayOptionsClose'})
            GetEventsWrapper(barberData,selectedDate, resourceLanes, services)
            resetHolidaysModal()
            setIsHolidayEdit(false)
          }).catch((error) => {
            notifyError("Unable to Edit holiday, please try again")
          }).finally((f) => {
            dispatch({type:'addHolidayClose'});
          })
      
          //edit holiday
        } else {
          AddHoliday(
            holidayTitle,
            selectedBarberForHoliday.value,
            startDateTimeHoliday,
            endDateTimeHoliday,
            isRecurringEvent,
            recurringFrequency,
            endReccuranceDate,
            ).then((res) => {
            notifySuccess("Holiday added")
            dispatch({type:'addHolidayClose'})
            GetEventsWrapper(barberData,selectedDate, resourceLanes, services)
            resetHolidaysModal()
          }).catch((error) => {
            notifyError("Unable to add holiday, please try again")
          }).finally((f) => {
            dispatch({type:'addHolidayClose'});
          })
        }
      }
    })
  } else {
  if (isHolidayEdit) {
    console.log(holidayToEdit)
    EditHoliday(
      holidayToEdit,
      holidayTitle,
      selectedBarberForHoliday.value,
      startDateTimeHoliday,
      endDateTimeHoliday,
      isRecurringEvent,
      recurringFrequency,
      endReccuranceDate,
      editOption
      ).then((res) => {
      notifySuccess("Holiday Edited")
      dispatch({type:'editHolidayOptionsClose'})
      GetEventsWrapper(barberData,selectedDate, resourceLanes, services)
      resetHolidaysModal()
      setIsHolidayEdit(false)
    }).catch((error) => {
      notifyError("Unable to Edit holiday, please try again")
    }).finally((f) => {
      dispatch({type:'addHolidayClose'});
    })

    //edit holiday
  } else {
    AddHoliday(
      holidayTitle,
      selectedBarberForHoliday.value,
      startDateTimeHoliday,
      endDateTimeHoliday,
      isRecurringEvent,
      recurringFrequency,
      endReccuranceDate,
      ).then((res) => {
      notifySuccess("Holiday added")
      dispatch({type:'addHolidayClose'})
      GetEventsWrapper(barberData,selectedDate, resourceLanes, services)
      resetHolidaysModal()
    }).catch((error) => {
      notifyError("Unable to add holiday, please try again")
    }).finally((f) => {
      dispatch({type:'addHolidayClose'});
    })
  }
}

}
}

function onNavigate(newDate) {
  var date = new Date(newDate)
  setSelectedDate(date);
  GetEventsWrapper(barberData, date, resourceLanes, services);
  setIsAcorOpen(false)
}

const onForwardDate = () => {
  var date = new Date(selectedDate)
  date.setDate(date.getDate() + 1)
  setSelectedDate(date)
  GetEventsWrapper(barberData, date, resourceLanes, services)
  setIsAcorOpen(false)
}

const onBackwardsDate = () => {
  var date = new Date(selectedDate)
  date.setDate(date.getDate() - 1)
  setSelectedDate(date)
  GetEventsWrapper(barberData, date, resourceLanes, services)
  setIsAcorOpen(false)
}

const calendarFormats = useMemo(() => ({
  timeGutterFormat: 'HH:mm'
}), [])

return (
  <div className="h-80">
    <PageTitle activeMenu="Calender" motherMenu="" />
    <div className="row">
    <ToastContainer />
    {showCalendar
    ?
    <div className='mb-4'>
    <Accordion
      expanded={isAccorOpen}
      onChange={(e) => {setIsAcorOpen(!isAccorOpen)}}
      >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
      {selectedDate.toDateString()}
      </AccordionSummary>
      <AccordionDetails>
        <div className="row">
          <div className='col-lg-6 col-md-6 col-sm-12' style={{display:'flex', justifyContent:'center'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StaticDatePicker
                format="DD/MM/YYYY"
                onChange={(e) => {onNavigate(new Date(e));}}
                slotProps={{ toolbar: { hidden: true }, actionBar: { actions: [] } }}
                value={dayjs(selectedDate)}
              />
            </LocalizationProvider>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12" style={{display:'flex,', justifyContent:'center', margin: 'auto'}}>
            <form className="">
              <div className=''>
                {barberData === null
                ?
                <p>loading</p>
                :
                barberData.map((item, index) => {
                  return (
                    <div key={index}>
                      <div >
                        <div >
                          <Link
                            style={{
                              padding:'0',
                              fontSize:'0.8rem',
                              width:'100%',
                              backgroundColor: resourceLanes.filter(x => x.value === item.barberId).length !== 1 ? 'grey': '',
                              borderColor: resourceLanes.filter(x => x.value === item.barberId).length !== 1 ? 'grey': ''
                            }}
                            to={"#"}
                            onClick={() => {onClickBarberName(item.barberId, item.name)}}
                            className="btn btn-primary mb-1 ms-1">{item.name}
                          </Link>
                        </div>
                      </div>
                    </div>
                  );
                })
                }
                </div>
              </form>
          </div>
        </div>
    </AccordionDetails>
  </Accordion>
  </div>
  :
  null
     }

      <div className={'col-lg-12'}>
        <div className="card">
          <div className="calendar-body card-body">
            <div if="story--props--resources">
                <div className="calendar-style">
                  {showCalendar
                  ?
                  <div>
                  <div className="mb-2" style={{display: 'block', marginBottom:'5rem'}}>
                    <Link to={"#"} onClick={() => {dispatch({type:'addEventOptionsOpen'}); resetAppointmentModal(); setIsAcorOpen(false);}} style={{position:'absolute', right:'2rem'}} className="btn-custom btn-primary mb-1 ms-1">Add Event</Link>
                  </div>
                  <div className="mb-2">
                <div className='calendar-toolbar-nav-buttons'>
                <IconButton className='nav-back' aria-label="delete" size="large">
            <ArrowBackIcon fontSize='medium' onClick={onBackwardsDate} />
          </IconButton>
          <Button className="btn-custom mb-3" onClick={() => {setSelectedDate(new Date()); GetEventsWrapper(barberData, new Date(), resourceLanes, services); setIsAcorOpen(false);}} style={{backgroundColor: isSelectedDateToday ? 'var(--primary)' : 'grey', border:'none'}}>Today</Button>
          <IconButton className='nav-next' aria-label="delete" size="large">
          <ArrowForwardIcon fontSize='medium' onClick={onForwardDate}/>
          </IconButton>
          </div>
                </div>
                </div>
                :
                null
                }

                {showCalendar
                ?
                <DnDCalendar
                formats={calendarFormats}
                defaultDate={today}
                date={selectedDate}
                defaultView={Views.DAY}
                events={eventData}
                localizer={localizer}
                resourceIdAccessor="value"
                resources={resourceLanes}
                resourceTitleAccessor="label"
                onEventDrop={e => {onEventDrop(e)}}
                onEventResize={e => {onEventResize(e)}}
                onDoubleClickEvent={(e) => {onSelectEvent(e)}}
                onNavigate={onNavigate}
                min={minDateTime}
                max={maxDateTime}
                resizable
                resizableAccessor={'isResizable'}
                step={20}
                // formats = {formats(test)}
                selectable
                scrollToTime={selectedDate}
                timeslots={1}
                toolbar={false}
                longPressThreshold={150}
                views={views}
                draggableAccessor={'isDraggable'}
                selectableAccessor={false}
                showMultiDayTimes={true}
                // onDragging={(e) => {console.log('dragging')}}
                onSelectSlot={(e) => {onSelectSlot(e)}}
                eventPropGetter={(myEventsList) => {
                  const backgroundColor = myEventsList.colorEvento ? myEventsList.colorEvento : 'blue';
                  const borderRadius = myEventsList.isCalanderBlock ? '0' : '';
                  const border = myEventsList.isCalanderBlock ? 'none' : '';
                  const color = myEventsList.isCalanderBlock ? '#dedede' : ''
                  const withClass = myEventsList.isCalanderBlock ? 'calendar-block-out-background' : ''
                  const full = myEventsList.fullDay ? 'full' : ''
                  const app = myEventsList.eventType === 1 ? 'app' : ''
                  // const width = '100% !important';
                  return { style: { backgroundColor, borderRadius, border, color}, className: withClass + ' ' + full + ' ' + app}
                }}
              />
              :
              <div className="loader" role="status" aria-hidden="true"></div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Modal className="modal fade" show={state.addEventOptions} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Choose an event type</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'addEventOptionsClose'})}></Button>
        </div>
        <div className="modal-body" style={{display:'flex', justifyContent:'space-around'}}>
        <Link to={"#"} onClick={() => {dispatch({type:'addEventOptionsClose'}); dispatch({type:'AddAppointmentOpen'}); setIsAppointmentCancelled(false); setIsEditAppointment(false);}} className="btn-custom btn-primary mb-1 ms-1">Appointment</Link>
        <Link to={"#"} onClick={() => {dispatch({type:'addEventOptionsClose'}); dispatch({type:'addHolidayOpen'}); setIsHolidayEdit(false);}} className="btn-custom btn-primary mb-1 ms-1">Personal</Link>
        </div>
	    </div>
    </Modal>
    <Modal enforceFocus={false} className="modal fade" show={state.addAppointment} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{isEditAppointment? 'Edit Appointment' : 'Add Appointment'}</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'AddAppointmentClose'})}></Button>
        </div>
        <div className="modal-body">
          <form className="comment-form" onSubmit={onSubmitAppointmentClick}>
            <div className="row mb-3">
              <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                <label htmlFor="author" className="text-black font-w600">  Select Barber <span className="required">*</span> </label>
                <Select
                    placeholder="Barber"
                    closeMenuOnSelect={true}
                    options={barberList}
                    defaultValue={selectedBarber}
                    isDisabled={isAppointmentCancelled}
                    onChange={(e) => {setSelectedBarber({value:e.value ,label: e.label})}}
                />
                {appointmentErrors.barber && <div className="mt-2 text-danger fs-12">{appointmentErrors.barber}</div>}
              </div>
              <div className="col-lg-6  col-md-6  col-sm-6 col-6">
                <label htmlFor="author" className="text-black font-w600">  Select Service <span className="required">*</span> </label>
                <Select
                    placeholder="Services"
                    closeMenuOnSelect={true}
                    options={services}
                    defaultValue={selectedService}
                    isDisabled={isAppointmentCancelled}
                    onChange={(e) => {selectServiceHandler(e)}}
                />
                {appointmentErrors.service && <div className="mt-2 text-danger fs-12">{appointmentErrors.service}</div>}
              </div>
              <div className="mt-4 col-lg-6  col-md-6  col-sm-6 col-6">
                <label htmlFor="author" className="text-black font-w600">Choose a Date<span className="required">*</span> </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    minutesStep={20}
                    reduceAnimations
                    format="DD/MM/YYYY - HH:mm"
                    defaultValue={startDateTime}
                    disablePast
                    disabled={isAppointmentCancelled}
                    ampm={false}
                    skipDisabled
                    onChange={(e) => {startDateHandler(e);}}
                  />
                  {appointmentErrors.date && <div className="mt-2 text-danger fs-12">{appointmentErrors.date}</div>}
                </LocalizationProvider>
              </div>
              <div className="mt-4 col-lg-6  col-md-6  col-sm-6 col-6">
                <label htmlFor="author" className="text-black font-w600">Change duration<span className="required">*</span> </label>
                <Select
                  closeMenuOnSelect={true}
                  options={timeSelection}
                  value={duration}
                  isDisabled={isAppointmentCancelled}
                  onChange={(e) => addDuration(e)}
                />
              {appointmentErrors.duration && <div className="mt-2 text-danger fs-12">{appointmentErrors.duration}</div>}
              </div>
              {startDateTime !== undefined && duration !== undefined ?
              <p style={ isAppointmentCancelled ? {color:'rgba(200, 200, 200, 0.6)'}: null} className="mt-1 ms-1">
                {
                daysOfWeek[new Date(startDateTime).getDay()]
                + ' '+
                new Date(startDateTime).getDate()
                + ' ' +
                months[new Date(startDateTime).getMonth()]
                + ' ' +
                ("0" + new Date(startDateTime).getUTCHours()).slice(-2) +':' + ("0" + new Date(startDateTime).getUTCMinutes()).slice(-2)
                + ' - ' +
                ("0" + new Date(endDateTime).getUTCHours()).slice(-2) +':' + ("0" + new Date(endDateTime).getUTCMinutes()).slice(-2)
                }
              </p>
              :
              null
              }
              <div className="mt-4 col-12">
                <div className="" style={{display:'flex'}}>
                  {customerDetailsObject !== null ?
                    <div style={ isAppointmentCancelled ? {color:'rgba(200, 200, 200, 0.6)'}: null} className="">
                      {customerDetailsObject.firstName + " " + customerDetailsObject.lastName}
                      <br/>
                      {customerDetailsObject.email}
                      <br/>
                      {customerDetailsObject.mobile}
                    </div>
                    :
                    null
                }
                {!isAppointmentCancelled
                ?
                <div className="" style={{position:'absolute', right:'0', marginRight:'2rem'}}>
                    
                {customerDetailsObject === null
                ?
                <Link to={"#"} onClick={() => {dispatch({type:'addCustomerOpen'});}} className="btn-custom btn-primary mb-1 ms-1">Add Customer</Link>
                :
                <Link to={"#"} onClick={() => {dispatch({type:'addCustomerOpen'}); loadCustomerDetails();}} className="btn-custom btn-primary mb-1 ms-1">Edit Customer</Link>
                }
                {appointmentErrors.customer && <div className="ms-2 mt-2 text-danger fs-12">{appointmentErrors.customer}</div>}
              </div>
              :
              null
              }

                </div>
              </div>
              <div className="mt-4 col-lg-12">
                <div className="form-group mb-3">
                <label htmlFor="comment" className="text-black font-w600">Notes</label>
                <textarea
                  rows={7}
                  className="form-control"
                  name="customerNotes"
                  placeholder=""
                  disabled={isAppointmentCancelled}
                  value={appointmentNotes}
                  maxLength={250}
                  onChange={(e) => setAppointmentNotes(e.target.value)}
                  />
                  {appointmentErrors.appointmentNotes && <div className="mt-2 text-danger fs-12">{appointmentErrors.appointmentNotes}</div>}
                </div>
              </div>
              {/* <div className="col-lg-12">
                <div className="form-group" style={{display:'flex', justifyContent:'end'}}>
                    <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={onSubmitAppointmentClick}>{isEditAppointment ? 'Edit Appointment' : 'Add Appointment'}</Link>
                </div>
              </div> */}

              <div className="col-lg-12">
                {isEditAppointment ?
                  <div className="col-lg-12 mb-5">
                  <div className="form-group mb-3" style={{display:'block'}}>
                      {!isAppointmentCancelled ?<Link style={{position:'absolute', backgroundColor:'#e83b2e'}} to={"#"} onClick={() => {onCancelAppointment(0)}} className="mt-2 btn-custom btn-primary submit">Cancel</Link>
                      :
                      <Link style={{position:'absolute', backgroundColor:'#e83b2e'}} to={"#"} onClick={() => {onCancelAppointment(1)}} className="mt-2 btn-custom btn-primary submit">Undo</Link>
                      }
                      {!isAppointmentCancelled ? <Link style={{position:'absolute', right:'0', marginRight:'2rem'}} className="mt-2 btn-custom btn-primary" onClick={onSubmitAppointmentClick}>Edit Appointment</Link>: null}
                  </div>
                  </div>
                  
                  :
                  <div style={{display:'flex', justifyContent:'end'}}>
                  <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={onSubmitAppointmentClick}>Add Appointment</Link>
                  </div>
                }
							</div>
            </div>
            <p className='fs-14'>{appointmentCancelledByMessage}</p>
          </form>
        </div>
	    </div>
    </Modal>
    <Modal className="modal fade" show={state.addCustomer} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Add Customer</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => {dispatch({type:'addCustomerClose'}); setCustomerList([]) }}></Button>
        </div>
        <div className="modal-body">
          <form className="" onSubmit={OnCustomerSubmitClick}>
            <div className="row">
              {customerList.length > 0
              ?
              <>
                <div className="col-10">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600">  Select Customer</label>
                  <Select
                      placeholder="Select"
                      closeMenuOnSelect={true}
                      options={customerList}
                      onChange={(e) => {selectCustomerFromCustomerSelectionList(e)}}
                  />
                  <p className='fs-13 ms-1 mt-2'>{customerList.length} customers found</p>
                  {appointmentErrors.service && <div className="mt-2 text-danger fs-12">{appointmentErrors.service}</div>}
                  </div>
                </div>
                <div className="col-2">
                <div className="form-group mt-4" style={{display:'flex', justifyContent:'end'}}>
                  <IconButton aria-label="delete" size="large">
                    <CancelIcon fontSize='medium' onClick={() => {setCustomerList([]); setSearchCustomerKeyword();}}/>
                  </IconButton>
                </div>
              </div>
            </>
            :
            <>
              <div className="col-9">
                  <div className="form-group mb-3">
                    <label htmlFor="author" className="text-black font-w600">Customer Search</label>
                      <input
                        type="text"
                        className="form-control"
                        name="customerFirstName"
                        placeholder="Search"
                        onChange={(e) => setSearchCustomerKeyword(e.target.value)}
                      />
                      {searchForCustomerError && <div className="mt-2 text-danger fs-12">{searchForCustomerError}</div>}
                  </div>
                </div>
                <div className="col-3">
                  <div className="form-group mt-4" style={{display:'flex', justifyContent:'end'}}>
                    <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={() => {searchForCustomer()}}>Search</Link>
                  </div>
                </div>
              </>
            }
            <Divider className='mb-4 mt-3' component="li" />

              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600">  First Name <span className="required">*</span> </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customerFirstName"
                      placeholder=""
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    {customerErrors.customerFirstName && <div className="mt-2 text-danger fs-12">{customerErrors.customerFirstName}</div>}
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600">  Last Name <span className="required">*</span> </label>
                    <input
                      type="text"
                      className="form-control"
                      name="customerLastName"
                      placeholder=""
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                    {customerErrors.customerLastName && <div className="mt-2 text-danger fs-12">{customerErrors.customerLastName}</div>}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600">Mobile</label>
                    <input
                      type="tel"
                      className="form-control"
                      name="customerMobile"
                      placeholder=""
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                    {customerErrors.customerMobile && <div className="mt-2 text-danger fs-12">{customerErrors.customerMobile}</div>}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3">
                  <label htmlFor="author" className="text-black font-w600">  Email <span className="required">*</span> </label>
                    <input
                      type="email"
                      className="form-control"
                      name="customerEmail"
                      placeholder=""
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    {customerErrors.customerEmail && <div className="mt-2 text-danger fs-12">{customerErrors.customerEmail}</div>}
                </div>
              </div>
                <div className="col-lg-12">
                  <div className="form-group mb-3">
                  <label htmlFor="comment" className="text-black font-w600">Notes</label>
                  <textarea
                    rows={7}
                    className="form-control"
                    name="customerNotes"
                    placeholder=""
                    value={customerNotes}
                    maxLength={250}
                    onChange={(e) => setCustomerNotes(e.target.value)}
                    />
                    {customerErrors.customerNotes && <div className="mt-2 text-danger fs-12">{customerErrors.customerNotes}</div>}
                  </div>
                </div>
              <div className='form-check check-switch custom-checkbox me-4 mb-2'>
                  <input
                      type='checkbox'
                      className='form-check-input'
                      id='isBlocked'
                      defaultChecked={customerBlocked}
                      onChange={(e) => { setCustomerBlocked(e.target.checked);}}
                      style={{backgroundColor:customerBlocked ? 'red' : '', borderColor:customerBlocked ? 'red' : ''}}
                  />
                  <label className='form-check-label' htmlFor='isBlocked'>
                      Blocked
                  </label>
              </div>
              <div className="col-lg-12">
                <div className="form-group mb-3" style={{display:'flex', justifyContent:'end'}}>
                  <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={OnCustomerSubmitClick}>Save</Link>
                </div>
							</div>
            </div>
          </form>
        </div>
	    </div>
    </Modal>
    <Modal enforceFocus={false} className="modal fade" show={state.addHoliday} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">{isHolidayEdit ? 'Edit Holiday' : 'Add Holiday'}</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => {dispatch({type:'addHolidayClose'}); resetHolidaysModal()}}></Button>
        </div>
        <div className="modal-body">
          <form className="" onSubmit={OnHolidaySubmitClick}>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12">
                <label htmlFor="author" className="text-black font-w600">Title<span className="required">*</span> </label>
                <input
                  type="text"
                  className="form-control"
                  name="holidayTitle"
                  placeholder="Title"
                  value={holidayTitle}
                  onChange={(e) => setHolidayTitle(e.target.value)}
                  />
                  {holidayErrors.holidayTitle && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayTitle}</div>}
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                  <label htmlFor="author" className="text-black font-w600">  Select Barber <span className="required">*</span> </label>
                  <Select
                      placeholder="Barber"
                      closeMenuOnSelect={true}
                      defaultValue={selectedBarberForHoliday}
                      options={barberList}
                      onChange={(e) => {setSelectedBarberForHoliday({value:e.value ,label: e.label})}}
                  />
                  {holidayErrors.holidayBarber && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayBarber}</div>}
                </div>
                <div className="mt-4 col-lg-6 col-md-6 col-12">
                <label htmlFor="author" className="text-black font-w600">Start Date<span className="required"> *</span> </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    minutesStep={20}
                    reduceAnimations
                    disablePast
                    defaultValue={startDateTimeHoliday}
                    format="DD/MM/YYYY - HH:mm"
                    ampm={false}
                    skipDisabled
                    onChange={(e) => {setStartDateTimeHoliday(e);}}
                  />
                  {holidayErrors.holidayStartDateTime && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayStartDateTime}</div>}
                </LocalizationProvider>
              </div>
              <div className="mt-4 col-lg-6 col-md-6 col-12">
                <label htmlFor="author" className="text-black font-w600">End Date<span className="required"> *</span> </label>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    minutesStep={20}
                    reduceAnimations
                    disablePast
                    ampm={false}
                    format="DD/MM/YYYY - HH:mm"
                    skipDisabled
                    minDateTime={startDateTimeHoliday}
                    defaultValue={endDateTimeHoliday}
                    onChange={(e) => {setEndDateTimeHoliday(e);}}
                  />
                  {holidayErrors.holidayEndDateTime && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayEndDateTime}</div>}
                </LocalizationProvider>
              </div>
              <div className='form-check check-switch custom-checkbox me-4 mb-2 mt-4'>
                  <input
                      type='checkbox'
                      className='form-check-input'
                      id='isRecurring'
                      defaultChecked={isRecurringEvent}
                      onChange={(e) => { setIsRecurringEvent(e.target.checked); setRecurringFrequency(); setEndRecuranceDate();}}
                  />
                  <label className='form-check-label' htmlFor='isRecurring'>
                      Recurring Event
                  </label>
              </div>
              {isRecurringEvent
              ?
              <div>
                <div className="ms-3">
                  <input onChange={(e) => {setRecurringFrequency(e.target.id)}} defaultChecked={recurringFrequency === "daily" ? true : false} className="form-check-input" type="radio" id="daily" name="fav_language" value="daily"/>
                  <label className='form-check-label' for="daily">Daily</label><br/>
                  <input onChange={(e) => {setRecurringFrequency(e.target.id)}} defaultChecked={recurringFrequency === "weekly" ? true : false} className="form-check-input" type="radio" id="weekly" name="fav_language" value="weekly"/>
                  <label className='form-check-label' for="weekly">Weekly</label><br/>
                  <input onChange={(e) => {setRecurringFrequency(e.target.id)}} defaultChecked={recurringFrequency === "monthly" ? true : false} className="form-check-input" type="radio" id="monthly" name="fav_language" value="monthly"/>
                  <label className='form-check-label' for="monthly">Monthly</label>
                  {holidayErrors.holidayRecurrenceFrequency && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayRecurrenceFrequency}</div>}
                </div>
                <div className="col-12 mt-3">
                  <label htmlFor="author" className="text-black font-w600">End recurrance </label>
                  <p>Leave empty, if there is not end date</p>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      minutesStep={20}
                      reduceAnimations
                      disablePast
                      format="DD/MM/YYYY"
                      ampm={false}
                      skipDisabled
                      defaultValue={endReccuranceDate}
                      // minDate={endDateTimeHoliday}
                      minDate={dayjs().add(1, 'day')}
                      onChange={(e) => {setEndRecuranceDate(e);}}
                    />
                    {holidayErrors.holidayRecurrenceEndDate && <div className="mt-2 text-danger fs-12">{holidayErrors.holidayRecurrenceEndDate}</div>}
                  </LocalizationProvider>
                </div>
              </div>
              :
              null
              }
              <div className="col-lg-12">
                {isHolidayEdit ?
                                <div className="col-lg-12 mb-5">
                                <div className="form-group mb-3" style={{display:'block'}}>
                                    {<Link style={{position:'absolute', backgroundColor:'#e83b2e'}} to={"#"} onClick={isRecurringEvent ? (e) => {dispatch({type:'deleteHolidayOptionsOpen'}); dispatch({type:'deleteHolidayOptionsOpen'}); dispatch({type:'addHolidayClose'})} : () => {onDeleteHoliday(4)}} className="mt-2 btn-custom btn-primary submit">Delete</Link>}
                                    {<Link style={{position:'absolute', right:'0', marginRight:'2rem'}} className="mt-2 btn-custom btn-primary" onClick={isHolidayEdit && isSelectedEventRecurring ? () => {dispatch({type:'editHolidayOptionsOpen'}); dispatch({type:'addHolidayClose'})} : (e) => {OnHolidaySubmitClick(e, 4);}}>Edit Holiday</Link>}
                                </div>
                                </div>
                                :
                                <div style={{display:'flex', justifyContent:'end'}}>
                                <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={OnHolidaySubmitClick}>Add Holiday</Link>
                                </div>
                }
							</div>
            </div>
          </form>
        </div>
	    </div>
    </Modal>
    <Modal className="modal fade" show={state.editHolidayOptions} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Edit Options</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'editHolidayOptionsClose'})}></Button>
        </div>
        <div className="modal-body">
          <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {OnHolidaySubmitClick(e, 1);}} className="btn btn-primary mb-2">All events in the series</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {OnHolidaySubmitClick(e, 2);}} className="btn btn-primary mb-2">This event and all future events</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {OnHolidaySubmitClick(e, 3);}} className="btn btn-primary mb-2">This event only</Link>
        </div>
        </div>
	    </div>
    </Modal>
    <Modal className="modal fade" show={state.editHolidayOptionsOnHolidayTimeSlotChange} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Edit Options on change slot</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'editHolidayOptionsOnHolidayTimeSlotChangeClose'})}></Button>
        </div>
        <div className="modal-body">
          <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {changeHolidaySlot(1);}} className="btn btn-primary mb-2">All events in the series</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {changeHolidaySlot(2);}} className="btn btn-primary mb-2">This event and all future events</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {changeHolidaySlot(3);}} className="btn btn-primary mb-2">This event only</Link>
        </div>
        </div>
	    </div>
    </Modal>
    <Modal className="modal fade" show={state.deleteHolidayOptions} centered>
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Delete holiday for</h5>
          <Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'deleteHolidayOptionsClose'})}></Button>
        </div>
        <div className="modal-body">
          <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {onDeleteHoliday(1);}} className="btn btn-primary mb-2">All events in the series</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {onDeleteHoliday(2);}} className="btn btn-primary mb-2">This event and all future events</Link>
        </div>
        <div>
          <Link style={{padding:'0', fontSize:'0.8rem', width:'100%', fontWeight:'normal'}} to={"#"} onClick={(e) => {onDeleteHoliday(3);}} className="btn btn-primary mb-2">This event only</Link>
        </div>
        </div>
	    </div>
    </Modal>
  </div>
  );
}