import PageTitle from "../../../layouts/PageTitle";

const Overview = () => {
    return (
        <div className="h-80">
        <PageTitle activeMenu="Overview" motherMenu="" />
        </div>
    );
}

export default Overview;