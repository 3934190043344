import React, { useReducer, useState, useEffect } from "react";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Select from "react-select";
import {
  AddService,
  GetServices,
  ActivateService,
  DisableService,
  DeleteService,
  EditService,
  GetServiceDurations
} from './Requests';
import {
  notifySuccess,
  notifyError
} from '../Response/ResposeHandler'
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ManageServices = () => {
  const [services, setServices] = useState([])
  const [timeSelection, setTimeSelection] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [serviceId, setServiceId] = useState(null)
  const [serviceStatus, setServiceStatus] = useState()
  const [isEdit, setIsEdit] = useState(false);
  const [serviceName, setServiceName] = useState('');
  const [serviceDuration, setServiceDuration] = useState(0);
  const [servicePrice, setServicePrice] = useState(0);
  const [serviceDescription, setServiceDescription] = useState('');
  const [isAddServiceButtonLoading, setIsAddServiceButtonLoading] = useState(false);
  const [isActivateServiceButtonLoading, setIsActivateServiceButtonLoading] = useState(false);
  const [isDisableServiceButtonLoading, setIsDisbaleServiceButtonLoading] = useState(false);
  const [isDeleteServiceButtonLoading, setIsDeleteServiceButtonLoading] = useState(false);
  let errorsObj = { serviceName: '', serviceDuration: '', servicePrice: '', serviceDescription: '' };
  const [errors, setErrors] = useState(errorsObj);
  const initialState = false;

  const reducer = (state, action) =>{
	switch (action.type){
		case 'addServiceOpen':
			return { ...state, sendMessage: true }
		case 'addServiceClose':			
			return { ...state, sendMessage: false }		
			
		default:
        return state	
	}	
}

useEffect(() => {
  GetServicesWrapper()
  GetServiceDurationsWrapper()
}, []);

const ClearIndicatorStyles = (base, state) => ({
  ...base,
  cursor: "pointer",
  color: state.isFocused ? "#525252" : "#7e7e7e",
});

const ClearIndicator = (props) => {
  const {
  getStyles,
  innerProps: { ref, ...restInnerProps },
  } = props;
  return (
    <div
        {...restInnerProps}
        ref={ref}
        style={getStyles("clearIndicator", props)}
    >
        <div style={{ padding: "0px 5px" }}>Clear</div>
    </div>
  );
};

  const [state, dispatch] = useReducer(reducer, initialState);

  function onSubmitClick(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (serviceName === '') {
        errorObj.serviceName = 'Name is required';
        error = true;
    }
    console.log(serviceDuration)
    if (serviceDuration === null || serviceDuration === undefined) {
      errorObj.serviceDuration = 'Duration is required';
      error = true;
    } else {
      if (serviceDuration.value === 0 || serviceDuration.value === null || serviceDuration.value === '') {
        errorObj.serviceDuration = 'Duration is required';
        error = true;
      }
    }
    if (servicePrice === '' || servicePrice === null || servicePrice < 0) {
      errorObj.servicePrice = 'Price is required';
      error = true;
    }
    if (serviceDescription === '') {
      errorObj.serviceDescription = 'Description is required';
      error = true;
    }
    setErrors(errorObj);
    if (error) {
  return;
} else {
  setIsAddServiceButtonLoading(true)
}

if (isEdit) {
  EditService(serviceId, serviceName, serviceDuration.value, servicePrice, serviceDescription, serviceStatus).then((res) => {
    notifySuccess("Service Edited")
    dispatch({type:'addServiceClose'})
    GetServicesWrapper()
  }).catch((error) => {
    notifyError("Unable to edit service, please try again")
  }).finally((f) => {
    setIsAddServiceButtonLoading(false)
  })
} else {
  AddService(serviceName, serviceDuration.value, servicePrice, serviceDescription).then((res) => {
    notifySuccess("Service added")
    dispatch({type:'addServiceClose'})
    GetServicesWrapper()
  }).catch((error) => {
    notifyError("Unable to add service, please try again")
  }).finally((f) => {
    setIsAddServiceButtonLoading(false)
  })
}
}

function OnDisableService(serviceId, name, description, duration, price) {
  setIsDisbaleServiceButtonLoading(true)
  DisableService(serviceId, name, description, duration, price).then((res) => {
    notifySuccess("Service disabled")
    GetServicesWrapper()
  }).catch((error) => {
    notifyError("Unable to disable service, please try again")
  }).finally((f) => {
    setIsDisbaleServiceButtonLoading(false)
  })
}

function OnActivateService(serviceId, name, description, duration, price) {
  setIsActivateServiceButtonLoading(true)
  ActivateService(serviceId, name, description, duration, price).then((res) => {
    notifySuccess("Service activated")
    GetServicesWrapper()
  }).catch((error) => {
    notifyError("Unable to activate service, please try again")
  }).finally((f) => {
    setIsActivateServiceButtonLoading(false)
  })
}

function OnDeleteService(serviceId, name, description, duration, price) {
  setIsDeleteServiceButtonLoading(true)
  DeleteService(serviceId, name, description, duration, price).then((res) => {
    notifySuccess("Service removed")
    GetServicesWrapper()
  }).catch((error) => {
    notifyError("Unable to remove service, please try again")
  }).finally((f) => {
    setIsDeleteServiceButtonLoading(false)
  })
}

function OnEditService(serviceId, name, description, duration, price, status) {
  setServiceId(serviceId)
  setIsEdit(true)
  setServiceName(name)
  setServiceDescription(description)
  setServiceDuration(timeSelection.filter(item => item.value === duration)[0])
  setServicePrice(price)
  setServiceStatus(status)
}

function GetServicesWrapper() {
  setIsLoading(true)
  GetServices().then((res) => {
    setServices(res.data)
    setIsLoading(false)
  }).catch((error) => {
    notifyError("Unable to retrieve services, please try again")
  })
}

function GetServiceDurationsWrapper() {
  GetServiceDurations().then((res) => {
    setTimeSelection(res.data)
  }).catch((error) => {
    notifyError("Unable to retrieve service durations, please try again")
  })
}

function resetModal() {
  setIsEdit(false)
  setServiceName('');
  setServiceDuration(null);
  setServicePrice(null);
  setServiceDescription('');
  setErrors(errorsObj);
}
  
  return (
      <div className="h-80">
        <PageTitle activeMenu="Mange Services" motherMenu="Barber Shop" />
        <div className="mb-2" style={{display: 'grid', justifyContent: 'end'}}>
            <Link to={"#"} className="btn-custom btn-primary mb-1 ms-1" onClick={() =>{ dispatch({type:'addServiceOpen'}); resetModal();}}>Add Service</Link>	
        </div>
        <ToastContainer />
        <div className="row">
            <div className="col-lg-12">
              <div className="card">
                  <div className="card-body ">
								<div tabindex="-1" className="profile-statistics">
                <Modal tabindex="-1" className="modal fade" show={state.sendMessage} centered>
									<div className="modal-content">
									  <div className="modal-header">
										<h5 className="modal-title">{isEdit ? 'Edit Service' : 'Add Service'}</h5>
										<Button variant="" type="button" className="btn-close" data-dismiss="modal" onClick={() => dispatch({type:'addServiceClose'})}>
										  
										</Button>
									  </div>
									  <div className="modal-body">
										<form className="comment-form" onSubmit={onSubmitClick}>
										  <div className="row">
											<div className="col-lg-6">
											  <div className="form-group mb-3">
												<label htmlFor="author" className="text-black font-w600">  Service Name <span className="required">*</span> </label>
												<input
                          type="text"
                          className="form-control"
                          name="serviceName"
                          placeholder=""
                          value={serviceName}
                          onChange={(e) => setServiceName(e.target.value)} />
                          {errors.serviceName && <div className="mt-2 text-danger fs-12">{errors.serviceName}</div>}
											  </div>
											</div>
											<div className="col-lg-6">
											  <div className="form-group mb-3">
												<label htmlFor="email" className="text-black font-w600"> Service Duration <span className="required">*</span></label>
                          <Select
                            closeMenuOnSelect={true}
                            components={{ ClearIndicator }}
                            styles={{ clearIndicator: ClearIndicatorStyles }}
                            // isMulti
                            defaultValue={serviceDuration}
                            options={timeSelection}
                            onChange={(e) => setServiceDuration(e)}
                          />
                          {errors.serviceDuration && <div className="mt-2 text-danger fs-12">{errors.serviceDuration}</div>}
											  </div>
											</div>
                      <div className="col-lg-6">
											  <div className="form-group mb-3">
												<label htmlFor="email" className="text-black font-w600"> Service Price (£) <span className="required">*</span></label>
												<input
                          type="number"
                          className="form-control"
                          placeholder=""
                          name="servicePrice"
                          value={servicePrice}
                          onChange={(e) => setServicePrice(e.target.value)}/>
                          {errors.servicePrice && <div className="mt-2 text-danger fs-12">{errors.servicePrice}</div>}
											  </div>
											</div>
											<div className="col-lg-12">
											  <div className="form-group mb-3">
												<label htmlFor="comment" className="text-black font-w600">Description</label>
												<textarea
                          rows={3}
                          className="form-control"
                          name="serviceDescription"
                          placeholder=""
                          value={serviceDescription}
                          maxLength={50}
                          onChange={(e) => setServiceDescription(e.target.value)}/>
                          {errors.serviceDescription && <div className="mt-2 text-danger fs-12">{errors.serviceDescription}</div>}
											  </div>
											</div>
											<div className="col-lg-12">
                        <div className="form-group mb-3" style={{display:'flex', justifyContent:'end'}}>
                            {isAddServiceButtonLoading
                            ?
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            :
                            // <input type="submit" value={isEdit? 'Edit Service' : 'Add Service'} className="submit btn btn-primary" name="submit"/>
                            <Link to={"#"} className="mt-2 btn-custom btn-primary" onClick={onSubmitClick}>{isEdit ? 'Edit Service' : 'Add Service'}</Link>
                            }
                        </div>
											</div>
										  </div>
										</form>
									  </div>
									</div>
								  </Modal>
                </div> 
                {isLoading ? <div className='barber-loader'></div>:
                services.length === 0 ? <div>No Services</div> :
                  <div className="table-responsive-sm">
                  <table className="table table-sm mb-0 text-black">
                    <thead>
                        <tr>
                          <th className="align-middle ps-2">Service Name</th>
                          <th className="align-middle ps-2">Service Duration</th>
                          <th className="align-middle ps-2">Description</th>
                          <th className="align-middle text-end ps-2">Price</th>
                          <th className="align-middle text-end ps-2">Status</th>
                          <th className="align-middle text-end">Action</th>
                        </tr>
                    </thead>
                    <tbody id="orders">
                      {services === null ? <p>loading</p> :
                        services.map((service, index) => {
                          return(
                            <tr key={service.serviceId} className="btn-reveal-trigger">
                            <td className="py-2">{service.name}</td>
                            <td className="py-2">{service.duration * 20} mins</td>
                            <td className="py-2" style={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth:'1rem'}}>{service.description}</td>
                            <td className="py-2 ps-2 text-end">£{service.price}</td>
                            <td className="py-2 ps-2 text-end">
                                <span className={`badge badge-sm light ${ service.status === 1 ? 'badge-success' : 'badge-danger'} `}>
                                  {service.status === 1 ? 'Active' : 'Disabled'}
                                </span>
                            </td>
                            <td className="py-2 text-end">
                                <Dropdown className="dropdown text-sans-serif">
                                  <Dropdown.Toggle
                                      variant=""
                                      className="btn btn-primary tp-btn-light sharp i-false"
                                      type="button"
                                      id="order-dropdown-0"
                                      data-toggle="dropdown"
                                      data-boundary="viewport"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                  >
                                      <span>
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            xmlnsXlink="http://www.w3.org/1999/xlink"
                                            width="18px"
                                            height="18px"
                                            viewBox="0 0 24 24"
                                            version="1.1"
                                        >
                                            <g stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                                              <rect y={0} width={24} height={24}/>
                                              <circle fill="#000000" cx={5} cy={12} r={2}/>
                                              <circle fill="#000000" cx={12} cy={12} r={2}/>
                                              <circle fill="#000000" cx={19} cy={12} r={2}/>
                                            </g>
                                        </svg>
                                      </span>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu
                                      className="dropdown-menu dropdown-menu-right border py-0"
                                      aria-labelledby="order-dropdown-0"
                                  >
                                      <div className="py-2">
                                        {service.status === 1 ?
                                          isDisableServiceButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                          <Link to={''} onClick={() => {OnDisableService(service.serviceId, service.name, service.description, service.duration, service.price);}} className="dropdown-item">Disable</Link>
                                          :
                                          isActivateServiceButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                          <Link onClick={() => {OnActivateService(service.serviceId, service.name, service.description, service.duration, service.price);}} className="dropdown-item">Active</Link>}
                                        <Link to={''} className="dropdown-item" onClick={() => {dispatch({type:'addServiceOpen'}); OnEditService(service.serviceId, service.name, service.description, service.duration, service.price, service.status);}}>Edit</Link>
                                        <div className="dropdown-divider" />
                                        {isDeleteServiceButtonLoading ? <span style={{marginLeft:'1rem'}} className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span> :
                                        <Link to={''} onClick={() => {OnDeleteService(service.serviceId, service.name, service.description, service.duration, service.price);}} className="dropdown-item text-danger">Delete</Link>}
                                      </div>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                          </tr>
                          )
                        })
                      }
                    </tbody>
                  </table>
              </div>
                }
                  </div>
              </div>
            </div>
        </div>
      </div>
  );
};

export default ManageServices;
