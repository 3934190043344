import React, { useContext } from "react";
import { useDispatch , useSelector } from 'react-redux';

import { ThemeContext } from "../../../context/ThemeContext";

/// React router dom
import { Link } from "react-router-dom";

import { navtoggle } from "../../../store/actions/AuthActions";

const NavHader = () => {
   const dispatch = useDispatch();
   const sideMenu = useSelector(state => state.sideMenu);
   const handleToogle = () => {
      dispatch(navtoggle("NAVTOGGLE"));
   };
   const {background} = useContext(ThemeContext);
   return (
      <div className="nav-header">
         {background.value === "light" ?
     
            <Link to="#" className="brand-logo">            
            <img
               className="logo-abbr svg-logo-path"
               src="Barberlogolight.png"
               alt="Logo"
            />
            <img
               className="svg-logo-text-path brand-title"
               src="Barbertextlogolight.png"
               alt="Logo"
            />
         </Link>
         :
     
         <Link to="#" className="brand-logo">            
         <img
            className="logo-abbr svg-logo-path"
            src="Barberlogodark.png"
            alt="Logo"
         />
         <img
            className="svg-logo-text-path brand-title"
            src="Barbertextlogodark.png"
            alt="Logo"
         />
      </Link>
      }
         <div id="hamburger" className="nav-control" onClick={() => handleToogle()}>
            <div className={`hamburger ${sideMenu ? "is-active" : ""}`}>
               <span className="line"></span>
               <span className="line"></span>
               <span className="line"></span>
            </div>
         </div>
      </div>
   );
};

export default NavHader;
