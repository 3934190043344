import axios from 'axios';
import * as endPoints from '../../../../Constants/Endpoints'

export function AddService(name, serviceDuration, servicePrice, description) {
    const duration = Number(serviceDuration)
    const price = Number(servicePrice)
    const serviceDetails = {
        name,
        duration,
        description,
        price
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Service, serviceDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function GetServices() {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Services,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function ActivateService(serviceId, name, description, serviceDuration, servicePrice) {
    const duration = Number(serviceDuration)
    const price = Number(servicePrice)
    const serviceDetails = {
        serviceId,
        name,
        duration,
        description,
        price
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.ActivateService, serviceDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function DisableService(serviceId, name, description, serviceDuration, servicePrice) {
    const duration = Number(serviceDuration)
    const price = Number(servicePrice)
    const serviceDetails = {
        serviceId,
        name,
        duration,
        description,
        price
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.DisableService, serviceDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function EditService(serviceId, name, serviceDuration, servicePrice, description, status) {
    const duration = Number(serviceDuration)
    const price = Number(servicePrice)
    const serviceDetails = {
        serviceId,
        name,
        duration,
        description,
        price,
        status
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Service, serviceDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function DeleteService(serviceId, name, description, serviceDuration, servicePrice) {
    const duration = Number(serviceDuration)
    const price = Number(servicePrice)
    const serviceDetails = {
        serviceId,
        name,
        duration,
        description,
        price
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.DeleteService, serviceDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}

export function GetServiceDurations() {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.ServiceDurations,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function AddBarber(
    name,
    serviceIds,
    imageFile,
    sundayStartHours,
    sundayEndHours,
    mondayStartHours, 
    mondayEndHours,
    tuesdayStartHours,
    tuesdayEndHours,
    wednesdayStartHours,
    wednesdayEndHours,
    thursdayStartHours,
    thursdayEndHours,
    fridayStartHours,
    fridayEndHours,
    saturdayStartHours,
    saturdayEndHours,
    barberColour
) {

    const formData = new FormData();
    formData.append("name", name)
    formData.append("serviceIds", serviceIds)
    formData.append("imageFile", imageFile)
    formData.append("sundayStartHours", sundayStartHours)
    formData.append("sundayEndHours", sundayEndHours)
    formData.append("MondayStartHours", mondayStartHours)
    formData.append("mondayEndHours", mondayEndHours)
    formData.append("tuesdayStartHours", tuesdayStartHours)
    formData.append("tuesdayEndHours", tuesdayEndHours)
    formData.append("wednesdayStartHours", wednesdayStartHours)
    formData.append("wednesdayEndHours", wednesdayEndHours)
    formData.append("thursdayStartHours", thursdayStartHours)
    formData.append("thursdayEndHours", thursdayEndHours)
    formData.append("fridayStartHours", fridayStartHours)
    formData.append("fridayEndHours", fridayEndHours)
    formData.append("saturdayStartHours", saturdayStartHours)
    formData.append("saturdayEndHours", saturdayEndHours)
    formData.append("barberColour", barberColour)

    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Barber, formData,
    {
        headers: {
            "Content-Type" : ["multipart/form-data"],
        },

        withCredentials: true
    });
}

export function EditBarber(
    barberId,
    name,
    serviceIds,
    imageFile,
    sundayStartHours,
    sundayEndHours,
    mondayStartHours, 
    mondayEndHours,
    tuesdayStartHours,
    tuesdayEndHours,
    wednesdayStartHours,
    wednesdayEndHours,
    thursdayStartHours,
    thursdayEndHours,
    fridayStartHours,
    fridayEndHours,
    saturdayStartHours,
    saturdayEndHours,
    barberColour) {

        const formData = new FormData();
        formData.append("barberId", barberId)
        formData.append("name", name)
        formData.append("serviceIds", serviceIds)
        formData.append("imageFile", imageFile)
        formData.append("sundayStartHours", sundayStartHours)
        formData.append("sundayEndHours", sundayEndHours)
        formData.append("MondayStartHours", mondayStartHours)
        formData.append("mondayEndHours", mondayEndHours)
        formData.append("tuesdayStartHours", tuesdayStartHours)
        formData.append("tuesdayEndHours", tuesdayEndHours)
        formData.append("wednesdayStartHours", wednesdayStartHours)
        formData.append("wednesdayEndHours", wednesdayEndHours)
        formData.append("thursdayStartHours", thursdayStartHours)
        formData.append("thursdayEndHours", thursdayEndHours)
        formData.append("fridayStartHours", fridayStartHours)
        formData.append("fridayEndHours", fridayEndHours)
        formData.append("saturdayStartHours", saturdayStartHours)
        formData.append("saturdayEndHours", saturdayEndHours)
        formData.append("barberColour", barberColour)

        return axios.put(
            process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Barber, formData,
        {
            headers: {
                "Content-Type" : ["multipart/form-data"],
            },
    
            withCredentials: true
        });
    }

export function GetBarbers() {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Barbers,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function GetBarberWorkingTimes() {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.BarberWorkingTimes,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function DeleteBarber(barberId) {
    const barberToDelete = {
        barberId
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.DeleteBarber, barberToDelete,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}

export function DisableBarber(barberId) {
    const barberToDelete = {
        barberId
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.DisableBarber, barberToDelete,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}

export function ActivateBarber(barberId) {
    const barberToDelete = {
        barberId
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.ActivateBarber, barberToDelete,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}