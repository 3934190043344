import axios from 'axios';
import * as endPoints from '../../../../Constants/Endpoints'

export function AddCustomer(
    firstName,
    lastName,
    mobile,
    email,
    customerNotes,
    customerIsBlocked,
    ) {

    const customerDetails = {
        firstName,
        lastName,
        mobile,
        email,
        customerNotes,
        customerIsBlocked,
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Customer, customerDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function AddAppointment(
    barberId,
    customerId,
    serviceId,
    startDateTime,
    endDateTime,
    durationTimeBlock,
    appointmentNotes,
    createdBy
    ) {

    const appointmentDetails = {
        barberId,
        customerId,
        serviceId,
        startDateTime,
        endDateTime,
        durationTimeBlock,
        appointmentNotes,
        createdBy
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Appointment, appointmentDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function EditAppointment(
    appointmentId,
    customerId,
    barberId,
    serviceId,
    startDateTime,
    endDateTime,
    durationTimeBlock,
    appointmentNotes,
    customerFirstName,
    customerLastName,
    customerMobile,
    customerEmail,
    customerNotes,
    customerIsBlocked,
    createdBy
    ) {
        
    const appointmentDetails = {
        appointmentId,
        customerId,
        barberId,
        serviceId,
        startDateTime,
        endDateTime,
        durationTimeBlock,
        appointmentNotes,
        customerFirstName,
        customerLastName,
        customerMobile,
        customerEmail,
        customerNotes,
        customerIsBlocked,
        createdBy
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Appointment, appointmentDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function UpdateAppointmentTimeSlot(
    appointmentId,
    barberId,
    startDateTime,
    endDateTime,
    ) {
        
    const appointmentDetails = {
        appointmentId,
        barberId,
        startDateTime,
        endDateTime,
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.UpdateAppointmentTimeSlot, appointmentDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function DeleteHoldiay(holidayId, startDateTime, deleteOption) {
    const holidayToDelete = {
        holidayId,
        startDateTime,
        deleteOption
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.DeleteHoliday, holidayToDelete,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}

export function CancelAppointment(appointmentId, cancelAppointmentOption, cancelAppointmentBy) {
    console.log(appointmentId)
    const appointmentToCancel = {
        appointmentId,
        cancelAppointmentOption,
        cancelAppointmentBy
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.CancelAppointment, appointmentToCancel,
    {
        headers: {
            "Content-Type": "application/json"
        },

        withCredentials: true
    });
}

export function UpdateHolidayTimeSlot(
    holidayId,
    barberId,
    startDateTime,
    endDateTime,
    editOption
    ) {
        console.log(editOption)
        
    const holidayDetails = {
        holidayId,
        barberId,
        startDateTime,
        endDateTime,
        editOption
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.UpdateHolidayTimeSlot, holidayDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function AddHoliday(
    Title,
    BarberId,
    startDateTime,
    endDateTime,
    isRecurringEvent,
    recurringFrequency,
    endReccurrenceDate
    ) {
    // const duration = Number(serviceDuration)
    // const price = Number(servicePrice)
    const holidayDetails = {
        BarberId,
        Title,
        startDateTime,
        endDateTime,
        isRecurringEvent,
        recurringFrequency,
        endReccurrenceDate
    }
    return axios.post(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Holiday, holidayDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function EditHoliday(
    holidayId,
    Title,
    BarberId,
    startDateTime,
    endDateTime,
    isRecurringEvent,
    recurringFrequency,
    endReccurrenceDate,
    editOption
    ) {
        console.log(holidayId)
    // const duration = Number(serviceDuration)
    // const price = Number(servicePrice)
    const holidayDetails = {
        holidayId,
        BarberId,
        Title,
        startDateTime,
        endDateTime,
        isRecurringEvent,
        recurringFrequency,
        endReccurrenceDate,
        editOption
    }
    return axios.put(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Holiday, holidayDetails,
    {
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function GetEvents(dateTime) {
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Events,
    { 
        params: {dateTime},
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function GetCustomers(keyword) {
    console.log(keyword)
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.Customers,
    {
        params: {keyword},
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}

export function GetExtremeWorkingTimes(barberIdsArray) {
    var barberIds = barberIdsArray.toString()
    return axios.get(
        process.env.REACT_APP_API_SERVICE_BASE_URL + endPoints.ExtremeWorkingTimes,
    { 
        params: {barberIds},
        headers: {
            "Content-Type": "application/json"
        },
        withCredentials: true
    });
}