import React, { useContext } from "react";
import {useSelector} from 'react-redux';
import {Routes, Route, Outlet } from 'react-router-dom'
import ProtectedRoute from "../services/ProtectedRoute";
import AuthenticationRouteGuard from "../services/AuthenticationRouteGuard";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
// import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";

/// Pages
import Registration from "./pages/Registration";
import Login from "./pages/Login";
import LockScreen from "./pages/LockScreen";
import Error400 from "./pages/Error400";
import Error403 from "./pages/Error403";
import Error404 from "./pages/Error404";
import Error500 from "./pages/Error500";
import Error503 from "./pages/Error503";

// /// Barber Shop
import ManageServices from "./components/Barber/BarberShop/ManageServices"
import EditHomeScreen from "./components/Barber/BarberShop/EditHomeScreen"
import ManageBarbers from "./components/Barber/BarberShop/MangeBarbers"


// /// Overview
import Overview from "./components/Barber/Overview/Overview"

// /// Calender
import Calendar from "./components/Barber/Calendar/Calendar"

// /// Bookings
import Bookings from "./components/Barber/Bookings/Bookings"

// /// Time Off
import TimeOff from "./components/Barber/TimeOff/TimeOff"

// /// Make Booking
import MakeBooking from "./components/Barber/MakeBooking/MakeBooking"


import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {  

  const allroutes = [

    /// Barber shop
    { url:"manage-services", component: <ManageServices /> },
    { url:"edit-home-screen", component: <EditHomeScreen /> },
    { url:"manage-barbers", component: <ManageBarbers /> },
   
    /// Overview
    { url:"", component: <Overview /> },

    /// Calendar
    { url:"calendar", component: <Calendar /> },

    /// Bookings
    { url:"Bookings", component: <Bookings /> },

      /// Time Off
    { url:"Time-off", component: <TimeOff /> },

    /// Make Booking
    { url:"make-a-booking", component: <MakeBooking /> }
  ];

  return (    
    <>

      <Routes >
      
        <Route path='page-register' element= {
          <AuthenticationRouteGuard>
        <Registration />
        </AuthenticationRouteGuard>

        } />
        <Route path='login' element= {
                <AuthenticationRouteGuard>

        <Login />
        </AuthenticationRouteGuard>

        } />
      </Routes>
    <ProtectedRoute>
        <Routes >
          {/* <Route path='page-login' element= {<Login />} />
          <Route path='page-register' element= {<Registration />} /> */}
          <Route path='page-lock-screen' element= {<LockScreen />} />
          <Route path='page-error-400' element={<Error400/>} />
          <Route path='page-error-403' element={<Error403/>} />
          <Route path='page-error-404' element={<Error404/>} />
          <Route path='page-error-500' element={<Error500/>} />
          <Route path='page-error-503' element={<Error503/>} />
          <Route  element={<MainLayout />} > 
              {allroutes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`${data.url}`}
                  element={

                    data.component

                    }
                />
              ))}
          </Route>
        </Routes>

        </ProtectedRoute>
        <ScrollToTop />
    </>
  );
};


function MainLayout(){
  const {sidebariconHover} = useContext(ThemeContext);
  const sideMenu = useSelector(state => state.sideMenu);
  return (
    <>      
        <div id="main-wrapper" 
          className={`show ${sidebariconHover ? "iconhover-toggle": ""} ${ !sideMenu ? "menu-toggle" : ""}`}          
        >  
          <Nav />
          <div className="content-body" style={{ minHeight: window.screen.height - 60 }}>
              <div className="container-fluid">
                <Outlet />                
              </div>
          </div>
          {/* <Footer /> */}
        </div>
      
    </>
  )

};

export default Markup;