import {
    formatError,
    login,
    signUp,
    logout
} from '../../services/AuthService';
import Swal from "sweetalert2";

export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';  
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';
export const NAVTOGGLE = 'NAVTOGGLE';
export const NAVCLOSE = 'NAVCLOSE';
export const NAVMOBILECLOSE = 'NAVMOBILECLOSE';

export function signupAction(email, password, barberShopName, navigate) {
    return (dispatch) => {
        signUp(email, password, barberShopName)
        .then((response) => {
            // dispatch(confirmedSignupAction(response.data));
            Swal.fire({
                icon: 'success',
                title: 'Account Created',
                text: "Go ahead and login to your new account",                        
            })
            navigate('/login');
        })
        .catch((error) => {
            if (error.response === undefined || error.response === null) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops',
                    text: 'Something went wrong, please try again',                        
                })
            } else {
                const errorMessage = formatError(error.response.data);
                dispatch(signupFailedAction(errorMessage));
            }
        });
    };
}

export function Logout(navigate) {
    return (dispatch) => {
        logout()
            .then((response) => {
                navigate(0);
            // dispatch(loginConfirmedAction(response.data));			              

            })
            .catch((error) => {		
                const errorMessage = formatError(error.response.data);
                console.log(error)
                dispatch(loginFailedAction(errorMessage));
            });
    };
}

export function loginAction(email, password, navigate) {
    return (dispatch) => {
        login(email, password)
            .then((response) => {
                navigate('/');
                navigate(0);
            })
            .catch((error) => {		
                if (error.response === undefined || error.response === null) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops',
                        text: 'Something went wrong, please try again',                        
                    })
                } else {
                const errorMessage = formatError(error.response.data);
                dispatch(loginFailedAction(errorMessage));

                }
            })
    };
}

export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
export function navtoggle(key){  
    return {        
        type: key,
    };
};